import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./RiskDiscoveryHitCollections.scss";
import Card from "../../card/Card";
import { useDispatch, useSelector } from "react-redux";
import DataGridSupplierSetup, {
  IDataGridColumn,
} from "../../../components/data-grid/DataGridSupplierSetup";
import RiskDiscoverySelectBox from "../risk-discovery-select-box/RiskDiscoverySelectBox";
import ArticlesAPI from "../../../api/articles/articlesAPI";
import PopupArticle from "../risk-discovery-popup/PopupArticle";
import { useLocation, useParams } from "react-router";
import {
  articleRiskCase,
  clearCollections,
  setCollections,
  setCollectionsLoading,
} from "../../../slices/hitsSlice";
import PopupRiskCases from "../popup-risk-cases/PopupRiskCases";
import { createColumns as createRiskDiscoveryColumns } from "../risk-discovery-hits/RiskDiscoveryHits";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Snackbar,
  SnackbarCloseReason,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ActionId from "../consts/actions";
import RiskDiscoveryApi from "../../../api/riskDiscovery/riskDiscoverylAPI";
import { extractIds } from "../../../utils/data-grid";
import DiscoveryType from "../consts/discoveryType";
import { setRiskCase, setSnackbar } from "../../../slices/discoverySlice";
import RiskCaseApi from "../../../api/riskCase/riskCase";
import { RiskCaseCollection } from "../../../api/riskDiscovery/riskDiscovery";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { SnackbarProps } from "../../../types/Snackbar";

interface Article {
  id: number;
  title: string;
  sourceDomain: string;
  positiveSentiment: number;
  negativeSentiment: number;
  suppliers: string;
  semanticSummary: string;
  url: string;
  sentimentMetrics: string;
  content: string;
  imageUrl: string;
  articleRiskCases?: any[];
}

interface Collection {
  id: number;
  collectionName: string;
  articleCount: number;
  avgSent: number;
  articles: Article[];
}

interface ArchivedKeywordModel {
  id: number;
  modelName?: string;
  items: any[];
}
interface ArchivedSupplierModel {
  id: number;
  name?: string;
}

interface KeywordModel {
  id: number;
  modelName: string;
  items: any[];
}

interface Supplier {
  id: number;
  name: string;
}

export default function RiskDiscoveryHitCollections() {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [collectionToBeArchived, setCollectionToBeArchived] = useState<any>();
  const [archiveSnackbar, setArchiveSnackbar] = useState<SnackbarProps>();

  const handleArchiveConfirmation = async (row: any) => {
    setOpenConfirmDialog(true);
    setCollectionToBeArchived(row);
  };

  const createColumns = (
    handleViewClick: (id: number) => void,
    handleCreateRiskCase: (collectionId: number) => Promise<void>,
    getCollections: () => void
  ): IDataGridColumn[] => [
    {
      field: "collectionName",
      headerName: "Title",
      type: "string",
      required: true,
    },
    {
      field: "articleCount",
      headerName: "Article Count",
      type: "number",
      required: true,
    },
    {
      field: "avgSent",
      headerName: "Avg. Sent",
      type: "number",
      required: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "string",
      required: false,
      renderCell: (params: any) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
          }}
        >
          <Button
            variant="text"
            color="primary"
            onClick={async () => {
              await handleCreateRiskCase(params.row.id);
              getCollections();
            }}
            style={{ outline: "none", boxShadow: "none", padding: 0 }}
          >
            Create case
          </Button>
          <Button
            style={{ padding: "0 10px" }}
            variant="text"
            color="primary"
            onClick={() => handleArchiveConfirmation(params.row)}
          >
            Archive
          </Button>
        </div>
      ),
    },
  ];

  const dispatch = useDispatch();
  const collections = useSelector((state: any) => state.hits.collections);

  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupRiskCasesVisible, setPopupRiskCasesVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [articles, setArticles] = useState<Article[]>([]);

  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const loading = useSelector((state: any) => state.hits.collections.loading);

  const isKwmRoute = location.pathname.includes("/kwm/");
  const isSupplierRoute = location.pathname.includes("/supplier/");
  const isArchivedKeywordRoute =
    location.pathname.includes("/archived-keyword/");
  const isArchivedSupplierRoute = location.pathname.includes(
    "/archived-supplier/"
  );

  const handleViewClick = (articleId: number) => {
    let foundIndex = -1;
    let allArticles: Article[] = [];
    collections.data.forEach((collection: Collection) => {
      if (collection.articles) {
        allArticles = [...allArticles, ...collection.articles];
      }
    });

    foundIndex = allArticles.findIndex(
      (article: Article) => article.id === articleId
    );

    setArticles(allArticles);
    setCurrentIndex(foundIndex);
    setPopupVisible(true);
  };

  const handleCreateRiskCase = async (collectionId: number) => {
    const selectedCollection = collections.data.find(
      (collection: any) => collection.id === collectionId
    );
    if (!selectedCollection) {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Collection not found.",
        })
      );
      return;
    }

    const selectedArticleIds = selectedCollection.articles.map(
      (article: any) => article.id
    );
    dispatch(
      articleRiskCase({
        articles: selectedCollection.articles,
      })
    );
    try {
      const riskCaseTemplate = await RiskCaseApi.getRiskCaseTemplate(
        selectedArticleIds
      );
      const updatedMeasures = riskCaseTemplate.measures.riskMeasures.map(
        (measure: any) => ({
          ...measure,
          selectedLevelId: measure.isRequired ? 1 : measure.selectedLevelId,
        })
      );

      const body: RiskCaseCollection = {
        generalInformation: {
          riskCaseId: 0,
          dateAdded: new Date().toISOString(),
          dateUpdated: new Date().toISOString(),
          dateDeleted: null,
          isActive: true,
          isDeleted: false,
          riskCaseName: `Risk Case for ${selectedCollection.collectionName}`,
          comments: riskCaseTemplate.generalInformation.comments,
          description: riskCaseTemplate.generalInformation.description,
          owner: riskCaseTemplate.generalInformation.owner
            ? {
                key: riskCaseTemplate.generalInformation.owner.key,
                name: riskCaseTemplate.generalInformation.owner.name || "",
              }
            : null,
          subscribers: riskCaseTemplate.generalInformation.subscribers,
          suppliers: riskCaseTemplate.generalInformation.suppliers,
          keywordModels: riskCaseTemplate.generalInformation.keywordModels,
        },
        measures: {
          ...riskCaseTemplate.measures,
          riskMeasures: updatedMeasures,
        },
        caseLogs: {
          caseLogs: [],
        },
        articles: {
          articles: [],
        },
      };

      // const riskCase = await RiskDiscoveryApi.createCollectionRiskCase(
      //   [collectionId],
      //   body
      // );
      const selectedKeywordModelsIds =
        body.generalInformation?.keywordModels[0]?.id;

      const riskCase = await RiskDiscoveryApi.createArticleRiskCase(
        body.generalInformation?.riskCaseName,
        selectedArticleIds,
        selectedKeywordModelsIds || null
      );

      dispatch(
        setRiskCase({
          data: riskCase,
          isPopupVisible: true,
          articleIds: selectedArticleIds,
        })
      );
      //   if (riskCase) {
      //     const selectedKeywordModelsIds = riskCase.keywordemodels?.map(
      //         (keywordModel: any) => keywordModel.id
      //     );
      //     await RiskDiscoveryApi.createArticleRiskCase(
      //         body.riskCaseName,
      //         selectedArticleIds,
      //         selectedKeywordModelsIds || null,
      //     );
      // } else {
      //     console.error('riskCase is undefined or null');
      // }

      dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: "Risk case created successfully.",
        })
      );
    } catch (error) {
      console.error("Error creating risk case:", error);
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Error creating risk case. Please try again.",
        })
      );
    }
  };

  const handlePopupClose = () => {
    setPopupVisible(false);
  };

  const handleRiskCaseClick = (articleId: number) => {
    let foundIndex = -1;
    let allArticles: Article[] = [];
    collections.data.forEach((collection: Collection) => {
      if (collection.articles) {
        allArticles = [...allArticles, ...collection.articles];
      }
    });

    foundIndex = allArticles.findIndex(
      (article: Article) => article.id === articleId
    );

    setArticles(allArticles);
    setCurrentIndex(foundIndex);
    setPopupRiskCasesVisible(true);
  };

  const handleRiskCasesClose = () => {
    setPopupRiskCasesVisible(false);
  };

  const archivedSupplierEntry = useSelector((state: any) => {
    if (isArchivedSupplierRoute) {
      return (
        Object.entries(
          state.discovery.keywordModel.archivedHits.bySupplier
        ) as [string, ArchivedSupplierModel][]
      ).find(([key, archivedModel]) => archivedModel.id === Number(id));
    }
    return undefined;
  });
  const keywordModelEntry = useSelector((state: any) => {
    if (isKwmRoute) {
      return (
        Object.entries(state.discovery.keywordModel.byKeywordModel) as [
          string,
          KeywordModel
        ][]
      ).find(([key, model]) => model.id === Number(id));
    }
    return undefined;
  });
  const archivedKeywordModelEntry = useSelector((state: any) => {
    if (isArchivedKeywordRoute) {
      return (
        Object.entries(
          state.discovery.keywordModel.archivedHits.byKeywordModel
        ) as [string, ArchivedKeywordModel][]
      ).find(([key, archivedModel]) => archivedModel.id === Number(id));
    }
    return undefined;
  });

  const supplierEntry = useSelector((state: any) => {
    if (isSupplierRoute) {
      return (
        Object.entries(state.discovery.keywordModel.bySupplier) as [
          string,
          Supplier
        ][]
      ).find(([key, supplier]) => supplier.id === Number(id));
    }
    return undefined;
  });

  const idForSelectBox = isKwmRoute
    ? keywordModelEntry?.[1]?.id
    : isSupplierRoute
    ? supplierEntry?.[1]?.id
    : isArchivedKeywordRoute
    ? archivedKeywordModelEntry?.[1]?.id
    : archivedSupplierEntry?.[1]?.id || 0;

  const fetchCollections = async () => {
    try {
      dispatch(setCollectionsLoading(true));
      let response;
      if (isKwmRoute) {
        response = await ArticlesAPI.getCollectionsByKWM(Number(id), false);
      } else if (isSupplierRoute) {
        response = await ArticlesAPI.getCollectionsBySupplier(
          Number(id),
          false
        );
      } else if (isArchivedKeywordRoute) {
        response = await ArticlesAPI.getCollectionsByKWM(Number(id), true);
      } else if (isArchivedSupplierRoute) {
        response = await ArticlesAPI.getCollectionsBySupplier(Number(id), true);
      }
      if (response) {
        dispatch(setCollections(response));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setCollectionsLoading(false));
    }
  };

  const columns = useMemo(() => {
    return createColumns(
      handleViewClick,
      handleCreateRiskCase,
      fetchCollections
    );
  }, [collections]);

  const ExpandableRow = React.memo(({ data }: { data: any }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const articleColumns = createRiskDiscoveryColumns(
      handleRiskCaseClick,
      handleViewClick,
      fetchCollections
    );

    return (
      <div>
        <DataGridSupplierSetup
          dataSource={data.articles}
          columns={articleColumns}
          loading={loading}
        />
      </div>
    );
  });

  const memoizedExpandableRow = useCallback(
    (props: any) => <ExpandableRow {...props} />,
    [collections]
  );

  useEffect(() => {
    dispatch(clearCollections());
    fetchCollections();
  }, [id]);

  const handleAction = async (
    selectedAction: { actionId?: ActionId },
    selectedRelatedAction: any
  ) => {
    const ids = extractIds(selectedRows);

    switch (selectedAction.actionId) {
      case ActionId.AddToRiskCase:
        await RiskDiscoveryApi.addCollectionsToRiskCase(
          selectedRelatedAction.id,
          ids
        );
        dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: `Collection${
              ids.length > 1 ? "s" : ""
            } successfully added to the risk case.`,
          })
        );
        break;
      case ActionId.CreateRiskCase:
        const selectedCollections = collections.data.filter((collection: any) =>
          ids.includes(collection.id)
        );
        const selectedArticles = selectedCollections.flatMap(
          (collection: any) => collection.articles || []
        );

        if (selectedArticles.length > 0) {
          dispatch(
            articleRiskCase({
              articles: selectedArticles,
            })
          );
        }
        const selectedArticleIds = selectedArticles.map(
          (article: any) => article.id
        );
        const riskCaseTemplate = await RiskCaseApi.getRiskCaseTemplate(
          selectedArticleIds
        );

        const updatedMeasures = riskCaseTemplate.measures.riskMeasures.map(
          (measure: any) => ({
            ...measure,
            selectedLevelId: measure.isRequired ? 1 : measure.selectedLevelId,
          })
        );

        const body: RiskCaseCollection = {
          generalInformation: {
            riskCaseId: 0,
            dateAdded: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
            dateDeleted: null,
            isActive: true,
            isDeleted: false,
            riskCaseName: selectedRelatedAction,
            comments: riskCaseTemplate.generalInformation.comments,
            description: riskCaseTemplate.generalInformation.description,
            owner: riskCaseTemplate.generalInformation.owner
              ? {
                  key: riskCaseTemplate.generalInformation.owner.key,
                  name: riskCaseTemplate.generalInformation.owner.name || "",
                }
              : null,
            subscribers: riskCaseTemplate.generalInformation.subscribers,
            suppliers: riskCaseTemplate.generalInformation.suppliers,
            keywordModels: riskCaseTemplate.generalInformation.keywordModels,
          },
          measures: {
            ...riskCaseTemplate.measures,
            riskMeasures: updatedMeasures,
          },
          caseLogs: {
            caseLogs: [],
          },
          articles: {
            articles: [],
          },
        };

        // const riskCase = await RiskDiscoveryApi.createCollectionRiskCase(
        //   ids,
        //   body
        // );
        const selectedKeywordModelsIds =
          body.generalInformation?.keywordModels[0]?.id;

        const riskCase = await RiskDiscoveryApi.createArticleRiskCase(
          body.generalInformation?.riskCaseName,
          selectedArticleIds,
          selectedKeywordModelsIds || null
        );

        dispatch(
          setRiskCase({
            data: riskCase,
            isPopupVisible: true,
            articleIds: selectedArticleIds,
          })
        );

        // if (riskCase) {
        //   const selectedKeywordModelsIds = riskCase?.keywordemodels?.map(
        //     (keywordModel: any) => keywordModel.id
        //   );
        //   await RiskDiscoveryApi.createArticleRiskCase(
        //     riskCase.riskCaseName,
        //     selectedArticleIds,
        //     selectedKeywordModelsIds || null
        //   );
        // } else {
        //   console.error('riskCase is undefined or null');
        // }

        break;
      case ActionId.Archive:
        await RiskDiscoveryApi.archiveCollections(ids);
        break;
    }

    fetchCollections();
  };

  const discoveryType = isKwmRoute
    ? DiscoveryType.ByKWM
    : DiscoveryType.BySupplier;

  const handleSelectAllRows = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      setSelectedRows(collections.data);
    }
  };

  const handleSelectRow = (row: any) => {
    const isSelected = selectedRows.find((r) => r.id === row.id);
    if (isSelected) {
      setSelectedRows(selectedRows.filter((r) => r.id !== row.id));
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleToggleExpandRow = (rowId: string) => {
    const isExpanded = expandedRows.includes(rowId);
    if (isExpanded) {
      setExpandedRows(expandedRows.filter((id) => id !== rowId));
    } else {
      setExpandedRows([...expandedRows, rowId]);
    }
  };

  const isAllSelected =
    collections.data.length > 0 &&
    selectedRows.length === collections.data.length;
  const isRowSelected = (rowId: string) =>
    selectedRows.some((row) => row.id === rowId);
  const isRowExpanded = (rowId: string) => expandedRows.includes(rowId);

  const closeSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setArchiveSnackbar((prev: any) => ({
      ...prev,
      open: false,
    }));
  };

  const handleArchived = async () => {
    try {
      await RiskDiscoveryApi.archiveCollections([collectionToBeArchived.id]);
      fetchCollections();

      setOpenConfirmDialog(false);

      setArchiveSnackbar({
        open: true,
        severity: "success",
        message: `Collection archived successfully`,
      });
    } catch (error) {
      console.error(error);
      setArchiveSnackbar({
        open: true,
        severity: "error",
        message: `Error archiving collection`,
      });
    }
  };

  return (
    <Card>
      <Snackbar
        open={archiveSnackbar?.open}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={archiveSnackbar?.severity === "error" ? null : 5000}
      >
        <Alert
          onClose={closeSnackbar}
          severity={archiveSnackbar?.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {archiveSnackbar?.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
          {"Are you sure you want to archive this collection?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleArchived} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <h4>Hit Collections</h4>

      <RiskDiscoverySelectBox
        mode="hitCollections"
        disabled={selectedRows.length === 0}
        kwmOrSupplierId={idForSelectBox}
        discoveryType={discoveryType}
        key={selectedRows.length}
        handleAction={handleAction}
      />

      <TableContainer className="table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                padding="checkbox"
                sx={{ width: "1%", padding: "5px" }}
              >
                <Checkbox
                  indeterminate={
                    selectedRows.length > 0 &&
                    selectedRows.length < collections.data.length
                  }
                  checked={isAllSelected}
                  onChange={handleSelectAllRows}
                />
              </TableCell>
              <TableCell sx={{ width: "1%", padding: "0" }} />
              {columns.map((column, index) => (
                <TableCell
                  key={column.field}
                  sx={{
                    paddingRight: index === columns.length - 1 ? "25px" : "0",
                    textAlign: index === columns.length - 1 ? "right" : "left",
                  }}
                >
                  {column.headerName}
                </TableCell>
              ))}
              <TableCell sx={{ width: "1%", padding: "0" }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {collections.data.length ? (
              collections.data.map((row: any) => (
                <React.Fragment key={row.id}>
                  <TableRow selected={isRowSelected(row.id)}>
                    <TableCell sx={{ width: "40px", padding: "5px" }}>
                      <Checkbox
                        checked={isRowSelected(row.id)}
                        onChange={() => handleSelectRow(row)}
                      />
                    </TableCell>
                    <TableCell sx={{ width: "40px", padding: "5px" }}>
                      <IconButton onClick={() => handleToggleExpandRow(row.id)}>
                        {isRowExpanded(row.id) ? (
                          <ExpandMore />
                        ) : (
                          <ChevronRight />
                        )}
                      </IconButton>
                    </TableCell>
                    {columns.map((column) => (
                      <TableCell key={column.field}>
                        {column.field === "actions"
                          ? column.renderCell?.({ row })
                          : row[column.field]}
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                  {isRowExpanded(row.id) && (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <Collapse
                          in={isRowExpanded(row.id)}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={1}>
                            <ExpandableRow data={row} />
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                  {loading ? <CircularProgress /> : "No Data Row"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {popupVisible && articles[currentIndex] && (
        <PopupArticle
          articles={articles}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          onClose={handlePopupClose}
        />
      )}

      <PopupRiskCases
        popupRiskCasesVisible={popupRiskCasesVisible}
        handleRiskCasesClose={handleRiskCasesClose}
        articles={articles}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
    </Card>
  );
}
