import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  TextField,
  Collapse,
  MenuItem,
  Select,
  LinearProgress,
  Box,
  Chip,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { es } from 'date-fns/locale';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  AttachFile as AttachFileIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import SelectBoxOption from '../../../../types/SelectBoxOption';
import GetAppIcon from '@mui/icons-material/GetApp';
import formatDate from '../../../../utils/format-date';
import RiskCaseApi from '../../../../api/riskCase/riskCaseApi';
import { useSelector } from 'react-redux';
import { Snackbar, AlertColor } from '@mui/material';
import CustomSnackbar from '../../../../components/custom-snackbar';

interface FileData {
  riskCaseLogEntryId?: number;
  filename: string;
  operationType: string;
  base64Data: string | null;
  riskCaseFileId: number;
}

interface CaseLogEntry {
  riskCaseLogEntryId?: number;
  date?: string | null;
  authorFullName?: string;
  entryText: string;
  files: FileData[];
}

interface CaseLogProps {
  owners?: SelectBoxOption[];
  formData?: any;
  updateCaseLogs?: (newCaseLogs: CaseLogEntry[]) => void;
  setFormData?: React.Dispatch<React.SetStateAction<any>>;
  triggerCaseLogUpdate?: () => void;
}

const CaseLog: React.FC<CaseLogProps> = ({
  owners,
  formData,
  updateCaseLogs,
  setFormData,
  triggerCaseLogUpdate
}) => {
  const [entries, setEntries] = useState<CaseLogEntry[]>(
    formData?.caseLogs?.caseLogs || []
  );
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [riskCaseData, setRiskCaseData] = useState(formData);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>('success');
  const [newEntry, setNewEntry] = useState<CaseLogEntry>({
    date: new Date().toLocaleDateString(),
    authorFullName: '',
    entryText: '',
    files: [],
  });

  const email = useSelector((state: any) => state.auth.user);

  const fetchDataUser = async () => {
    try {
      const user = await RiskCaseApi.getUserByEmail(email);
      setNewEntry((prevEntry) => ({
        ...prevEntry,
        authorFullName: user?.fullName || '',
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataUser();
  }, []);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleEditClick = (index: number) => {
    setEditingIndex(index);
    setNewEntry({
      ...entries[index],
      files: entries[index].files || [],
    });
    handleOpenModal();
  };

  const handleAddNewEntryClick = () => {
    setIsUploading(false);
    setEditingIndex(-1);
    setNewEntry({
      date: new Date().toLocaleDateString(),
      authorFullName: newEntry.authorFullName || '',
      entryText: '',
      files: [],
    });
    handleOpenModal();
  };

  const newCaseLog = async () => {
    try {
      let updatedCaseLogs;

      if (editingIndex !== -1) {
        updatedCaseLogs = riskCaseData.caseLogs.caseLogs.map((entry:CaseLogEntry, index:number) =>
          index === editingIndex
            ? {
                ...entry, 
                authorFullName: newEntry.authorFullName,
                entryText: newEntry.entryText,
                files: newEntry.files,
                date: newEntry.date || new Date().toISOString(),
              }
            : entry
        );
      } else {
        // Si no estamos en edición, creamos un nuevo log
        const newEntryWithId: CaseLogEntry = {
          ...newEntry,
          riskCaseLogEntryId: 0,
          date: newEntry.date || new Date().toISOString(),
          files: newEntry.files.map((file) => ({
            ...file,
            riskCaseFileId: 0, 
          })),
        };

        updatedCaseLogs = [...riskCaseData.caseLogs.caseLogs, newEntryWithId];
      }
      const sanitizedCaseLogs = updatedCaseLogs.map(({ date, ...rest }: CaseLogEntry) => rest);
      await RiskCaseApi.updateRiskCase({
        ...riskCaseData,
        caseLogs: {
          caseLogs: sanitizedCaseLogs 
        },
        articles: {
          articles: riskCaseData?.articles.articles.map((article: any) => ({
            id: article.id,
            articleId: article.articleId,
            title: article.title,
            addDate: article.addDate,
            articleCategories: [],
            articleCompanies: [],
            articleEntities: [],
            articleKeywords: [],
            articleLinks: [],
            articleRiskCases: article.articleRiskCase,
            authorByLine: article.authorByLine,
            content: article.content,
            dateAdded: article.dateAdded,
            dateDeleted: article.dateDeleted,
            dateUpdated: article.dateUpdated,
            description: article.description,
            imageUrl: article.imageUrl,
            isActive: article.isActive,
            isDeleted: article.isDeleted,
            language: article.language,
            medium: article.medium,
            negativeSentiment: article.negativeSentiment,
            neutralSentiment: article.neutralSentiment,
            positiveSentiment: article.positiveSentiment,
            pubDate: article.pubDate,
            refreshDate: article.refreshDate,
            score: article.score,
            semanticSummary: article.semanticSummary,
            sourceDomain: article.sourceDomain,
            sourceLocation: article.sourceLocation,
            supplierArticles: [],
            url: article.url,
          })),
        },
      });
      triggerCaseLogUpdate?.();

      if (!window.location.pathname.includes('/scrm/discovery')) {
        setSnackbarMessage('The case log has been successfully saved');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(error);
      if (!window.location.pathname.includes('/scrm/discovery')) {
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setSnackbarMessage(
          'An error occurred while trying to save the case log. Please try again.'
        );
      }
    }
  };

  const handleSaveClick = () => {
    let updatedCaseLogs: CaseLogEntry[];
    if (editingIndex === -1) {
      const { date, ...newEntryWithoutDate } = newEntry;
      const newEntryWithId: CaseLogEntry = {
        ...newEntryWithoutDate,
        date: newEntry.date || new Date().toISOString(),
        riskCaseLogEntryId: 0,
        files: newEntry.files.map((file) => ({
          ...file,
          riskCaseLogEntryId: 0,
        })),
      };
      updatedCaseLogs = [...entries, newEntryWithId];
    } else {
      updatedCaseLogs = entries.map((entry, index) =>
        index === editingIndex
          ? {
              ...newEntry,
              date: newEntry.date || new Date().toISOString(),
              riskCaseLogEntryId: 0,
              files: newEntry.files.map((file) => ({
                ...file,
                riskCaseLogEntryId: 0,
              })),
            }
          : entry
      );
    }
    newCaseLog();
    updateCaseLogs?.(updatedCaseLogs);
    setFormData?.((prevFormData: any) => ({
      ...prevFormData,
      caseLogs: { caseLogs: updatedCaseLogs },
    }));
    setEntries(updatedCaseLogs);
    setEditingIndex(null);
    handleCloseModal();
   
  };



  const handleCancelClick = () => {
    setEditingIndex(null);
  };

  const deleteConfirm = (index: number) => {
    setDeleteIndex(index);
    setOpenConfirmDialog(true);
  };

  const handleDeleteClick = async (index: number) => {
    try {
     
      const updatedCaseLogs = entries.filter((_, i) => i !== index);


      setEntries(updatedCaseLogs);
      try {
        await RiskCaseApi.updateRiskCase({
          ...riskCaseData,
          caseLogs: {
            caseLogs: updatedCaseLogs,
          },
          articles: {
            articles: riskCaseData?.articles.articles.map((article: any) => ({
              id: article.id,
              articleId: article.articleId,
              title: article.title,
              addDate: article.addDate,
              articleCategories: [],
              articleCompanies: [],
              articleEntities: [],
              articleKeywords: [],
              articleLinks: [],
              articleRiskCases: article.articleRiskCase,
              authorByLine: article.authorByLine,
              content: article.content,
              dateAdded: article.dateAdded,
              dateDeleted: article.dateDeleted,
              dateUpdated: article.dateUpdated,
              description: article.description,
              imageUrl: article.imageUrl,
              isActive: article.isActive,
              isDeleted: article.isDeleted,
              language: article.language,
              medium: article.medium,
              negativeSentiment: article.negativeSentiment,
              neutralSentiment: article.neutralSentiment,
              positiveSentiment: article.positiveSentiment,
              pubDate: article.pubDate,
              refreshDate: article.refreshDate,
              score: article.score,
              semanticSummary: article.semanticSummary,
              sourceDomain: article.sourceDomain,
              sourceLocation: article.sourceLocation,
              supplierArticles: [],
              url: article.url,
            })),
          },
        });
        updateCaseLogs?.(updatedCaseLogs);
        triggerCaseLogUpdate?.();
      } catch (error) {
        console.error(error);
      }

      if (!window.location.pathname.includes('/scrm/discovery')) {
        setSnackbarMessage('The case log has been successfully deleted.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error deleting case log:', error);
      if (!window.location.pathname.includes('/scrm/discovery')) {
        setSnackbarMessage(
          'An error occurred while trying to delete the case log. Please try again.'
        );
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } finally {
      setOpenConfirmDialog(false);
    }
  };

  useEffect(() => {
    setEntries(formData?.caseLogs?.caseLogs || []);
  }, [formData]);

  const handleProgres = () => {
    setIsUploading(true);
    setUploadProgress(0);
    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 90) {
          clearInterval(interval);
          return prevProgress;
        }
        return prevProgress + 5;
      });
    }, 700);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsUploading(true);

      const totalFiles = e.target.files.length;
      let filesProcessed = 0;

      const processFile = (index: number) => {
        if (index >= totalFiles) {
          setUploadProgress(100);
          setTimeout(() => setIsUploading(false), 1000);
          return;
        }

        const file = e.target.files![index];
        const reader = new FileReader();

        reader.onload = (event) => {
          const base64Data = event.target?.result as string;
          filesProcessed++;
          const progress = (filesProcessed / totalFiles) * 100;
          setUploadProgress(progress);

          setNewEntry((prev) => ({
            ...prev,
            files: [
              ...prev.files,
              {
                filename: file.name,
                operationType: 'ADD',
                base64Data: base64Data.split(',')[1],
                riskCaseFileId: 0,
              },
            ],
          }));
          processFile(index + 1);
        };

        reader.readAsDataURL(file);
      };

      processFile(0);
    } else {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const handleRemoveFile = (fileToRemove: FileData) => {
    setNewEntry((prev) => ({
      ...prev,
      files: prev.files.filter(
        (file) => file.filename !== fileToRemove.filename
      ),
    }));
  };

  const handleDownload = async (file: FileData) => {
    try {
      const response = await RiskCaseApi.getRiskCaseFileById(
        file.riskCaseFileId
      );
      const base64Data = response?.file.data;

      const base64String = base64Data.split(',')[1] || base64Data;
      const byteCharacters = atob(base64String);

      const byteNumbers = new Uint8Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteNumbers], {
        type: 'application/octet-stream',
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = response.filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error en la descarga:', error);
    }
  };

  return (
    <TableContainer
      component={Paper}
      style={{ padding: '20px', marginTop: '20px' }}
    >
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddNewEntryClick}
        style={{ margin: '10px' }}
      >
        Add New Log Entry
      </Button>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle variant="h6" component="div" sx={{ fontSize: '26px' }}>
          {editingIndex === -1 ? 'New Case Log' : 'Edit Case Log'}
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <DatePicker
              label="Date"
              value={newEntry.date ? new Date(newEntry.date) : null}
              onChange={(newDate) => {
                setNewEntry({
                  ...newEntry,
                  date: newDate ? newDate.toISOString() : null,
                });
              }}
              slotProps={{
                textField: {
                  style: { marginBottom: '5px', marginTop: '10px' },
                },
              }}
            />
          </LocalizationProvider>
          <Select
            margin="dense"
            label="Author"
            fullWidth
            value={newEntry.authorFullName || ''}
            onChange={(e) =>
              setNewEntry({
                ...newEntry,
                authorFullName: e.target.value as string,
              })
            }
            disabled
          >
            {owners && owners.length > 0 ? (
              owners.map((owner) => (
                <MenuItem key={owner.key} value={owner.name}>
                  {owner.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">No authors available</MenuItem>
            )}
          </Select>
          <TextField
            margin="dense"
            label="Entry"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={newEntry.entryText}
            onChange={(e) =>
              setNewEntry({ ...newEntry, entryText: e.target.value })
            }
          />
          <Box
            sx={{
              margin: '10px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Button
              variant="contained"
              component="label"
              onClick={handleProgres}
            >
              Upload Files
              <input type="file" hidden multiple onChange={handleFileUpload} />
            </Button>
            {isUploading && (
              <Box sx={{ mt: 2, width: '100%', maxWidth: '300px' }}>
                <LinearProgress
                  variant={
                    uploadProgress < 90 ? 'indeterminate' : 'determinate'
                  }
                  value={uploadProgress}
                  sx={{
                    height: 8,
                    borderRadius: 4,
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 4,
                    },
                  }}
                />
               
              </Box>
            )}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
              {newEntry.files.map((file, index) => (
                <Chip
                  key={index}
                  icon={<AttachFileIcon sx={{ fontSize: '0.8rem' }} />}
                  label={file.filename}
                  onDelete={() => handleRemoveFile(file)}
                  sx={{
                    bgcolor: 'grey.200',
                    color: 'text.primary',
                    '& .MuiChip-deleteIcon': {
                      color: 'text.secondary',
                    },
                    height: '24px',
                    fontSize: '0.75rem',
                  }}
                />
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveClick} color="primary">
            {editingIndex === -1 ? 'Add Entry' : 'Save Changes'}
          </Button>
        </DialogActions>
      </Dialog>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Author</TableCell>
            <TableCell>Entry</TableCell>
            <TableCell align="center">Files</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((entry, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  {entry.date ? formatDate(entry.date) : ''}
                </TableCell>
                <TableCell>{entry.authorFullName}</TableCell>
                <TableCell>{entry.entryText}</TableCell>
                <TableCell align="center">
                  {entry.files && entry.files.length > 0 ? (
                    <Tooltip
                      title={
                        <List dense>
                          {entry.files.map((file, fileIndex) => (
                            <ListItem
                              key={fileIndex}
                              button
                              onClick={() => handleDownload(file)}
                            >
                              <ListItemIcon>
                                <GetAppIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText primary={file.filename} />
                            </ListItem>
                          ))}
                        </List>
                      }
                      componentsProps={{
                        tooltip: {
                          sx: { pointerEvents: 'auto' } as const,
                        },
                      }}
                    >
                      <IconButton size="small">
                        <AttachFileIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    onClick={() => handleEditClick(index)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => deleteConfirm(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
          {'Are you sure you want to delete?'}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (deleteIndex !== null) {
                handleDeleteClick(deleteIndex);
              }
              setOpenConfirmDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </TableContainer>
  );
};

export default CaseLog;
