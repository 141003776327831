import Drawer from "devextreme-react/drawer";
import ScrollView from "devextreme-react/scroll-view";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./side-nav-toolbar.scss";
import { Template } from "devextreme-react/core/template";
import { useMenuPatch } from "../../utils/patches";
import RootMenu from "../../components/root-menu/RootMenu";
import SubMenu from "../../components/sub-menu/SubMenu";
import SuppliersAPI from "../../api/suppliers/suppliersAPI";
import { addSelectedSupplier, addSuppliers } from "../../slices/suppliersSlice";
import { useDispatch, useSelector } from "react-redux";
import RiskDiscoveryApi from "../../api/riskDiscovery/riskDiscoverylAPI";
import { setKeywordModel } from "../../slices/discoverySlice";
import RiskCaseApi from "../../api/riskCase/riskCaseApi";
import { setMenuRiskCase } from "../../slices/riskCasesSlice";
import KeywordModelAPI from "../../api/keywordModel/keywordModelApi";
import { addKeywordModelsMenu } from "../../slices/keywordModelSlice";

const scrmOptions = [
	{ id: "1", text: "Dashboard", href: "/scrm/dashboard" },
	{ id: "2", text: "Suppliers" },
	{ id: "3", text: "Risk Models" },
	{ id: "4", text: "Discovery" },
	{ id: "5", text: "Risk Cases" },
	{ id: "6", text: "Admin" },
];

const userAdminOptions = [
	{ id: "1", text: "Users", href: "/user-admin/users" },
	{ id: "2", text: "Roles", href: "/user-admin/roles" },
	{ id: "3", text: "Activities", href: "/user-admin/activities" },
];

const nsnOptions = [
	{ id: "1", text: "Search", href: "/nsn/search" },
	{ id: "2", text: "Batch", href: "/nsn/batch" },
];

const dmsmsOptions = [
	{ id: "1", text: "Admin Settings", href: "/dmsms/admin" },
];

export default function SideNavToolbar({ children }) {
	const [patchCssClass, onMenuReady] = useMenuPatch();
	const [selectedMenuOption, setSelectedMenuOption] = useState("Root");
	const [menuOptions, setMenuOptions] = useState(userAdminOptions);

	const location = useLocation();
	const dispatch = useDispatch();
	const suppliers = useSelector((state) => state.suppliers.allSuppliers);

	const returnToRootMenu = () => {
		setSelectedMenuOption("Root");
	};

	const fetchSuppliers = async () => {
		try {
			const data = await SuppliersAPI.getAllSuppliers();
			dispatch(addSuppliers({ allSuppliers: data }));
		} catch (error) {
			console.error(error);
		}
	};
	const clearSelectedSupplier = () => {
		dispatch(
			addSelectedSupplier({
				selectedSupplier: {
					id: "",
					supplierName: "",
				},
			})
		);
	};

	const fetchKeywordModelsMenu = async () => {
		try {
			const data = await KeywordModelAPI.getRiskModelsMenuItems();
			dispatch(
				addKeywordModelsMenu({ keywordsModelsMenu: data.keywordModels })
			);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchMenuDiscovery = async () => {
		try {
			const data = await RiskDiscoveryApi.getRiskDiscoveryMenu();
			dispatch(setKeywordModel(data));
		} catch (error) {
			console.error(error);
		}
	};

	const fetchMenuRiskCase = async () => {
		try {
			const data = await RiskCaseApi.getRiskCasesMenu();
			dispatch(setMenuRiskCase(data));
		} catch (error) {
			console.error(error);
		}
	};

  useEffect(() => {
    fetchMenuDiscovery();
    fetchMenuRiskCase();
    fetchKeywordModelsMenu();
    if (suppliers.length === 0) {
      fetchSuppliers();
    }
  }, []);

	const url = useEffect(() => {
		if (location.pathname.startsWith("/scrm")) {
			setMenuOptions(scrmOptions);
			if (location.pathname.startsWith("/scrm/dashboard")) {
				setSelectedMenuOption("Dashboard");
			} else if (location.pathname.startsWith("/scrm/suppliers")) {
				setSelectedMenuOption("Suppliers");
			} else if (location.pathname.startsWith("/scrm/risk-models")) {
				setSelectedMenuOption("Risk Models");
			} else if (location.pathname.startsWith("/scrm/discovery")) {
				setSelectedMenuOption("Discovery");
			} else if (location.pathname.startsWith("/scrm/risk-cases")) {
				setSelectedMenuOption("Risk Cases");
			} else if (location.pathname.startsWith("/scrm/admin")) {
				setSelectedMenuOption("Admin");
			}
		} else if (location.pathname.startsWith("/user-admin")) {
			setMenuOptions(userAdminOptions);
			if (location.pathname.startsWith("/user-admin/users")) {
				setSelectedMenuOption("Users");
			} else if (location.pathname.startsWith("/user-admin/roles")) {
				setSelectedMenuOption("Roles");
			} else if (location.pathname.startsWith("/user-admin/activities")) {
				setSelectedMenuOption("Activities");
			}
		} else if (location.pathname.startsWith("/nsn")) {
			setMenuOptions(nsnOptions);

			if (location.pathname.startsWith("/nsn/search")) {
				setSelectedMenuOption("Search");
			} else if (location.pathname.startsWith("/nsn/batch")) {
				setSelectedMenuOption("Batch");
			}
		} else if (location.pathname.startsWith("/dmsms")) {
			setMenuOptions(dmsmsOptions);

			if (location.pathname.startsWith("/dmsms/admin")) {
				setSelectedMenuOption("Admin Settings");
			}
		}
	}, [
		location.pathname.startsWith("/nsn"),
		location.pathname.startsWith("/scrm"),
		location.pathname.startsWith("/user-admin"),
		location.pathname.startsWith("/dmsms"),
	]);

	const handleMenuOptionClick = (option) => {
		switch (option) {
			case "Suppliers":
				setSelectedMenuOption(option);
				clearSelectedSupplier();
				break;
		}

		if (option === "Suppliers") {
			setSelectedMenuOption(option);
			clearSelectedSupplier();
		}
	};

	const renderMenu = () => {
		if (selectedMenuOption === "Suppliers") {
			return (
				<SubMenu
					returnToRootMenu={returnToRootMenu}
					text={selectedMenuOption}
					setSelectedMenuOption={setSelectedMenuOption}
				/>
			);
		}
		if (
			selectedMenuOption === "Risk Models" ||
			selectedMenuOption === "Risk Cases" ||
			selectedMenuOption === "Admin" ||
			selectedMenuOption === "Discovery"
		) {
			return (
				<SubMenu
					returnToRootMenu={returnToRootMenu}
					text={selectedMenuOption}
					setSelectedMenuOption={setSelectedMenuOption}
				/>
			);
		} else {
			return (
				<RootMenu
					menuOptions={menuOptions}
					selectedMenuOption={selectedMenuOption}
					setSelectedMenuOption={setSelectedMenuOption}
					handleMenuOptionClick={handleMenuOptionClick}
				/>
			);
		}
	};

	return (
		<div className={"side-nav-outer-toolbar"}>
			<Drawer
				className={["drawer", patchCssClass].join(" ")}
				opened={true}
				template={"menu"}
			>
				<div className={"container"}>
					<ScrollView className={"layout-body with-footer"}>
						<div className={"content"}>{children}</div>
					</ScrollView>
				</div>
				<Template name={"menu"}>
					<div
						className={"dx-swatch-additional side-navigation-menu"}
					>
						{renderMenu()}
					</div>
				</Template>
			</Drawer>
		</div>
	);
}
