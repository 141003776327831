import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
  AlertColor,
  Box,
  CircularProgress
} from '@mui/material';
import './UserAccessControl.scss';
import { AppDto, UsersAdminDto } from '../../../../api/types/types';
import UserAdminAPI from '../../../../api/userAdmin/userAdminAPI';
import CustomSnackbar from '../../../../components/custom-snackbar';




interface UserAccessControlProps {
  userId: number;
  superAdmin: boolean;
}

function UserAccessControl({ userId, superAdmin}: UserAccessControlProps) {
  const [access, setAccess] = useState<AppDto[]>([]);
  const [appRoles, setAppRoles] = useState<{ [appId: number]: any[] }>({});
  const [userRoles, setUserRoles] = useState<any[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>('success');
  const [isLoading, setIsLoading] = useState(false);

  const handleToggle = (id: number) => {
    setAccess(
      access.map((app) =>
        app.id === id ? { ...app, enabled: !(app as any).enabled } : app
      )
    );
  };
  


  const handleChangeRole = (id: number, role: string) => {
    setAccess(access.map((app) => (app.id === id ? { ...app, role: role } : app)));
  };
  const fetchApps = async () => {
    try {
      const response = await UserAdminAPI.getAllApp();
      const apps = response.map((app: AppDto) => ({
        ...app,
        // enabled: app.id === userRoles?.appId && !!userRoles?.roleName,
        // role: userRole?.appId === app.id ? userRole?.roleName : undefined,
        isDisabled: superAdmin,
      }));
      setAccess(apps);
      return apps;
    } catch (error) {
      console.error('Error fetching apps:', error);
      return [];
    }
  };

  
  const fetchAppRoles = async (appId: number) => {
    try {
      const roles = await UserAdminAPI.getRoleAppById(appId);
      setAppRoles(prevRoles => ({
        ...prevRoles,
        [appId]: roles
      }));
      setIsLoading(false);
    } catch (error) {
      console.error(`Error fetching roles for app ${appId}:`, error);
    }
  };

  const fetchUserRoles = async () => {
    try {
      const roles = await UserAdminAPI.getRoleUserById(userId);
      setUserRoles(roles);
      
      // Update access state based on user roles
      setAccess(prevAccess => prevAccess.map(app => ({
        ...app,
        enabled: roles.some((role: any) => role.appId === app.id),
        role: roles.find((role: any) => role.appId === app.id)?.roleName
      })));
    } catch (error) {
      console.error(`Error fetching roles for user ${userId}:`, error);
    }
  };




  useEffect(() => {
    setIsLoading(true);
    const initializeData = async () => {
      const apps = await fetchApps();
      setAccess((prevAccess) =>
        prevAccess.map((app) => ({
          ...app,
          isDisabled: superAdmin,
        }))
      );
      if (Array.isArray(apps)) {
        apps.forEach(app => {
          fetchAppRoles(app.id);
        });
      }
      fetchUserRoles();
    };
  
    initializeData();
  }, [superAdmin]);

  
  const getRoleIdByNameAndAppId = (roleName: string, appId: number) => {
    const appRolesList = appRoles[appId] || [];
    const role = appRolesList.find(r => r.roleName === roleName);
    return role ? role.id : null;
  };

  const handleSave = async () => {
    try {
      const selectedRoles = access
        .filter(app => app.enabled && app.role)
        .map(app => ({
          appId: app.id,
          roleId: getRoleIdByNameAndAppId(app.role ?? 'default', app.id)
        }))
        .filter(role => role.roleId !== null);

      if (selectedRoles.length === 0) {
        return;
      };
      const response = await UserAdminAPI.putUserIdRoles(userId, selectedRoles.map(role => role.roleId));
      setSnackbarOpen(true);
      setSnackbarMessage('Roles added successfully');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error adding user roles:', error);
    }
  };


  return (
    <div className="access-control-container">
      {isLoading ? (
        <Box
         display="flex" justifyContent="center" alignItems="center" height={200}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {access.map((app: AppDto) => (
            <FormGroup key={app.id} className="formGroup">
          <FormControlLabel
            control={
              <Switch
                checked={app.enabled}
                onChange={() => handleToggle(app.id)}
                disabled={!superAdmin}
              />
            }
            label={app.appName}
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Select...</InputLabel>
            <Select
            value={app.role || ''}
            onChange={(e) => handleChangeRole(app.id, e.target.value as string)}
            label="Select..."
            disabled={!superAdmin}
          >
            <MenuItem value="">
              <em>Select...</em>
            </MenuItem>
            {appRoles[app.id]?.map((role) => (
              <MenuItem key={role.id} value={role.roleName}>
                {role.roleName}
              </MenuItem>
            ))}
          </Select>
          </FormControl>
        </FormGroup>
      ))}
      <Button
        variant="contained"
        color="primary"
        className="saveButton"
        disabled={!superAdmin}
        onClick={handleSave}
      >
        Save
      </Button>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
      </>
    )}
    </div>
  );
}

export default UserAccessControl;
