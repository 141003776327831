import { Snackbar, Alert } from "@mui/material";
import { createPortal } from "react-dom";

export default function ValidationSnackbar({
	open,
	message,
	severity,
	onClose,
}) {
	return createPortal(
		<Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			open={open}
			autoHideDuration={3000}
			onClose={onClose}
			//key={snackBarState.message}
		>
			<Alert severity={severity}>{message}</Alert>
		</Snackbar>,
		document.getElementById("app-snackbar")
	);
}
