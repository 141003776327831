import React, { useEffect, useState } from 'react';
import TreeView from 'devextreme-react/tree-view';
import './RiskCasesMenu.scss';
import RiskCaseApi from '../../api/riskCase/riskCaseApi';

import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { LoadIndicator } from 'devextreme-react';
import { setMenuRiskCase } from '../../slices/riskCasesSlice';
import { RiskCaseMenuDto } from '../../api/types/types';
import { setRiskCurrentCaseName, setSelectedMenuItem } from '../../slices/riskCasesSlice';
import { useLocation } from 'react-router-dom';

interface TreeViewItem {
  id: number | string;
  text: string;
  expanded?: boolean;
  modelId?: number | string;
  type?: string;
  items?: TreeViewItem[];
  nonSelectable?: boolean;
}

interface items {
  id: number;
  description: string;
}

const initialItems: TreeViewItem[] = [
  {
    id: -1,
    text: 'Current',
    expanded: true,
    items: [
      {
        id: 2,
        text: 'By KWM ',
        expanded: true,
        items: [],
      },
      {
        id: 3,
        text: 'By Supplier',
        expanded: true,
        items: [],
      },
    ],
  },

  {
    id: 4,
    text: 'Archived Hits',
    expanded: true,
    items: [
      {
        id: 5,
        text: 'By KWM',
        expanded: true,
        items: [],
      },
      {
        id: 6,
        text: 'By Supplier',
        expanded: true,
        items: [],
      },
    ],
  },
];

const renderTreeViewItem = (
  item: TreeViewItem,
  selectedItem: string | number | null,
  location: any
) => {
  const pathName = location.pathname;

  const itemClass =
    (pathName.includes('/current') && item.id === -1) ||
    item.modelId === selectedItem
      ? 'tree-view-item selected'
      : item.nonSelectable
      ? 'tree-view-item non-selectable'
      : item.items && item.items.length > 0
      ? 'tree-view-item title'
      : 'tree-view-item value';
  return <div className={itemClass}>{item.text}</div>;
};

const RiskCasesMenu: React.FC = () => {
  const [items, setItems] = useState<TreeViewItem[]>(initialItems);
  const selectedItem = useSelector(
    (state: any) => state.riskCases.selectedMenuItem
  );
  const { id } = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuRiskCase:RiskCaseMenuDto = useSelector(
    (state: any) => state.riskCases.menuRiskCase
  );

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (Object.keys(menuRiskCase).length === 0) {
        const data = await RiskCaseApi.getRiskCasesMenu();
        dispatch(setMenuRiskCase(data));
      }
      const kwmItems = Object.keys(menuRiskCase?.byKeywordModel).map((key, index) => ({
        id: `kwm-${index + 1}`,
        text: key,
        expanded: true,
        modelId: menuRiskCase.byKeywordModel[key].id,
        type: 'kwm',
        items: (menuRiskCase.byKeywordModel[key].items || []).map(
          (item: items, itemIndex: number) => ({
            id: `keyworModel-${index + 1}-riskCases-${itemIndex + 1}`,
            text: item.description,
            modelId: item.id,
            type: 'kwm-risk-cases',
            items: [],
          }))
      })).sort((a, b) => a.text.localeCompare(b.text));

      const suppliersItems = Object.keys(menuRiskCase.bySupplier).map((key, index) => ({
        id: `supplier-${index + 1}`,
        text: key,
        expanded: true,
        modelId: menuRiskCase.bySupplier[key].id,
        type: 'supplier',
        items: (menuRiskCase.bySupplier[key].items || []).map(
          (item: items, itemIndex: number) => ({
            id: `supplier-${index + 1}-riskCases-${itemIndex + 1}`,
            text: item.description,
            modelId: item.id,
            type: 'supplier-risk-cases',
            items: [],
          }))
          .sort((a: TreeViewItem, b: TreeViewItem) => a.text.localeCompare(b.text))
      })).sort((a, b) => a.text.localeCompare(b.text));

      const archivedHitsByKeywordModelItems = Object.keys(
        menuRiskCase.archived.byKeywordModel
      ).map((key, index) => ({
        id: `archived-kwm-${index + 1}`,
        text: key,
        expanded: true,
        modelId: menuRiskCase.archived.byKeywordModel[key].id,
        type: 'archived-keyword',
        items: (menuRiskCase.archived.byKeywordModel[key].items || []).map(
          (item: items, itemIndex: number) => ({
            id: `archived-keyword-${index + 1}-riskCases-${itemIndex + 1}`,
            text: item.description,
            modelId: item.id,
            type: 'archived-keyword-risk-cases',
            items: [],
          }))
          .sort((a: TreeViewItem, b: TreeViewItem) => a.text.localeCompare(b.text))
      })).sort((a, b) => a.text.localeCompare(b.text));

      const archivedHitsBySupplierItems = Object.keys(
        menuRiskCase.archived.bySupplier
      ).map((key, index) => ({
        id: `archived-supplier-${index + 1}`,
        text: key,
        expanded: true,
        modelId: menuRiskCase.archived.bySupplier[key].id,
        type: 'archived-supplier',
        items: (menuRiskCase.archived.bySupplier[key].items || []).map(
          (item: items, itemIndex: number) => ({
            id: `archived-supplier-${index + 1}-riskCases-${itemIndex + 1}`,
            text: item.description,
            modelId: item.id,
            type: 'archived-supplier-risk-cases',
            items: [],
          }))
          .sort((a: TreeViewItem, b: TreeViewItem) => a.text.localeCompare(b.text))
      })).sort((a, b) => a.text.localeCompare(b.text));

      const updatedItems = [...initialItems];

      if (updatedItems[0]?.items?.[0]?.items) {
        updatedItems[0].items[0].items = kwmItems;
      }

      if (updatedItems[0]?.items?.[1]?.items) {
        updatedItems[0].items[1].items = suppliersItems;
      }

      if (updatedItems[1]?.items?.[0]?.items) {
        updatedItems[1].items[0].items = archivedHitsByKeywordModelItems;
      }

      if (updatedItems[1]?.items?.[1]?.items) {
        updatedItems[1].items[1].items = archivedHitsBySupplierItems;
      }
      setItems(updatedItems);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
};

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [menuRiskCase]);

  const handleItemClick = (e: { itemData: TreeViewItem }) => {
    const itemData = e.itemData as TreeViewItem;

    dispatch(setSelectedMenuItem(itemData.modelId ?? itemData.id));

    if(itemData.id === -1){ // Current
      return navigate('/scrm/risk-cases/current/');
    }

    if (itemData.modelId) {
      let url = '';
      if (itemData.type === 'kwm-risk-cases') {
        url = `/scrm/risk-cases/kwm/${itemData.modelId}`;
      } else if (itemData.type === 'supplier-risk-cases') {
        url = `/scrm/risk-cases/supplier/${itemData.modelId}`;
      } else if (itemData.type === 'archived-supplier-risk-cases') {
        url = `/scrm/risk-cases/archived-supplier/${itemData.modelId}`;
      } else if (itemData.type === 'archived-keyword-risk-cases') {
        url = `/scrm/risk-cases/archived-kwm/${itemData.modelId}`;
      }

      dispatch(setRiskCurrentCaseName(itemData.text));
      return navigate(url);
    }
  };

  return (
    <div id="treeViewContainer" className="tree-view-container">
      {isLoading ? (
        <div className="spinner">
          <LoadIndicator />
        </div>
      ) : (
        <TreeView
          items={items}
          width={300}
          itemRender={(item) => renderTreeViewItem(item, selectedItem, location)}
          onItemClick={handleItemClick as any}
          selectByClick={true}
        />
      )}
    </div>
  );
};

export default RiskCasesMenu;
