// @ts-ignore: Implicit any module
import httpClient from "../configs/axiosConfig";
// @ts-ignore: Implicit any module
import config from "../../config";
import { KeywordModelDto, SelectBoxOptionDto } from "../../api/types/types";

const { KEYWORD_MODELS_SERVICE_URL, ALTRUITY_API_URL } = config;

interface Bodykeywordmodel {
  modelName: string;
  keywords: string | null;
  ownerId: number;
  searchFrequencyId: number;
  sentimentRuleId: number;
  isActive: boolean;
}
interface KeywordModel {
  id: number;
  modelName: string;
}

const KeywordModel = {
  getOwnersDropdown: async function () {
    const { data } = await httpClient.get<{result: SelectBoxOptionDto[]}>(
      `${ALTRUITY_API_URL}/KeywordModels/GetOwnersDropdown`
    );
    return data.result;
  },

  getKeywordModelById: async function (id: number) {
    const { data } = await httpClient.get<{result: KeywordModel[]}>(
      `${KEYWORD_MODELS_SERVICE_URL}/KeywordModel/${id}`
    );
    return data.result;
  },

  getKeywordById: async function (id: number) {
    const { data } = await httpClient.get<{result: KeywordModelDto[]}>(
      `${ALTRUITY_API_URL}/KeywordModels/GetKeywordModelById/${id}`
    );
    return data.result;
  },

  getAllKeywordModel: async function () {
    const { data } = await httpClient.get(`${KEYWORD_MODELS_SERVICE_URL}/KeywordModel/GetAll`);
  const response = data.result.sort((a:KeywordModel, b:KeywordModel) =>
    a.modelName.localeCompare(b.modelName)
  );
    return response
  },
  getAllSentimentRule: async function () {
    const { data } = await httpClient.get(`${KEYWORD_MODELS_SERVICE_URL}/Sentimentrule/GetAll`);
    return data.result;
  },
  getAllSearchFrequency: async function () {
    const { data } = await httpClient.get(`${KEYWORD_MODELS_SERVICE_URL}/SearchFrequency/GetAll`);
    return data.result;
  },
  createKeywordModel: async function (body:Bodykeywordmodel) {
    const { data } = await httpClient.post(`${ALTRUITY_API_URL}/KeywordModels/CreateKeywordModel`,body);
    return data;
  },

  getRiskModelsMenuItems: async function () {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/KeywordModels/GetRiskModelsMenuItems`);
    return data.result;
  },

  updateKeywordModel: async function (body: any, id: number) {
    const { data } = await httpClient.put(`${ALTRUITY_API_URL}/KeywordModels/UpdateKeywordModel/${id}`,body);
    return data;
  },

  getSynonyms: async function (keyword: string) {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/KeywordModels/GetSynonyms?word=${keyword}`);
    return data.result;
  },
  
  runPerigonFlowByKeywordModel: async function (keywordModelId: number) {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/KeywordModels/RunPerigonFlowByKeywordModel`,
      { keywordModelId: keywordModelId }
    );
    return data.result;
  },
  
};

export default KeywordModel;
