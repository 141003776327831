import React from "react";
import { createPortal } from "react-dom";
import {
	Alert,
	CircularProgress,
	Slide,
	SlideProps,
	Snackbar,
} from "@mui/material";

function SlideTransition(props: SlideProps) {
	return <Slide {...props} direction="down" />;
}

interface FileDownloadProps {
	isLoading: boolean;
	type: string;
}

export default function FileDownloadSnackbar({
	isLoading,
	type,
}: FileDownloadProps) {
	return createPortal(
		<Snackbar
			open={isLoading}
			TransitionComponent={SlideTransition}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
		>
			<Alert severity="info" icon={<CircularProgress size={24} />}>
				Exporting {type === "csv" ? "CSV" : "Excel "} file...
			</Alert>
		</Snackbar>,
		document.getElementById("app-snackbar")!
	);
}
