import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Link,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import './PopupRiskCases.scss';

interface RiskCase {
  id: number;
  riskCaseName: string;
  ownerId: number;
  creationDate: string;
  isActive: boolean;
}

interface Article {
  id: number;
  title: string;
  pubDate?: string;
  sourceDomain: string;
  url: string;
  sentimentMetrics: string;
  semanticSummary: string;
  content: string;
  imageUrl: string;
  suppliers: string;
  positiveSentiment: number;
  negativeSentiment: number;
  supplierArticles?: SupplierArticle[];
  articleKeywords?: ArticleKeyword[];
  articleRiskCases?: ArticleRiskCases[];
}

interface ArticleRiskCases {
  id: number;
  riskCaseId: number;
  articleId: number;
}

interface SupplierArticle {
  id: string;
  name: string;
}

interface ArticleKeyword {
  id: string;
  name: string;
}

interface RiskCasePopupProps {
  popupRiskCasesVisible: boolean;
  handleRiskCasesClose: () => void;
  articles: Article[];
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const PopupRiskCases: React.FC<RiskCasePopupProps> = ({
  popupRiskCasesVisible,
  handleRiskCasesClose,
  articles,
  currentIndex,
  setCurrentIndex,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const article = articles[currentIndex];
  const allRiskCases = useSelector(
    (state: any) => state.riskCases?.allRiskCases
  );
  const owners = useSelector((state: any) => state.permission.owners);
  const riskCases = allRiskCases?.filter((riskCase: RiskCase) =>
    article?.articleRiskCases?.some((ar) => ar.riskCaseId === riskCase.id)
  ) as RiskCase[];

  const riskCasesWithOwners = riskCases.map((riskCase: RiskCase) => {
    const owner = owners.find((owner: any) => owner.id === riskCase.ownerId);

    return {
      ...riskCase,
      owner: owner ? owner.label : 'Unknown',
      riskCaseName: capitalizeFirstLetter(riskCase.riskCaseName),
    };
  });

  const handleNavigation = (id: number) => {
    const currentLocation = location.pathname;
    
    const locationMapping = [
      {
        sourceLocationPattern: 'archived-keyword',
        mapToLocation: '/scrm/risk-cases/archived-kwm/',
      },
      {
        sourceLocationPattern: 'archived-supplier',
        mapToLocation: '/scrm/risk-cases/archived-supplier/',
      },
      {
        sourceLocationPattern: 'kwm',
        mapToLocation: '/scrm/risk-cases/kwm/',
      },
      {
        sourceLocationPattern: 'supplier',
        mapToLocation: '/scrm/risk-cases/supplier/',
      },
    ];
  
    // Find the matching route pattern
    const matchedMapping = locationMapping.find(mapping =>
      currentLocation.includes(mapping.sourceLocationPattern)
    );
  
    // If a match is found, navigate to the mapped location with the ID
    if (matchedMapping) {
      navigate(`${matchedMapping.mapToLocation}${id}`);
    } else {
      console.error(`Failed to determine target url based on current location: ${location.pathname}`)
    }
  };
  
  return (
    <Dialog
      open={popupRiskCasesVisible}
      onClose={() => {}}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: { maxHeight: '80vh', overflowY: 'auto' },
      }}
    >
      <Typography variant="h5" component="div" className="dialog-title">
        Risk Cases
      </Typography>
      <IconButton
        aria-label="close"
        onClick={handleRiskCasesClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#ffffff',
         
        }}
      >
        <CloseIcon sx={{fontSize: 20}} />
      </IconButton>

      <DialogContent dividers className="dialog-scrollbar">
      <Grid container spacing={2}>
          {riskCasesWithOwners.length > 0 ? (
            riskCasesWithOwners.map((riskCase: any) => (
              <Grid item xs={12} sm={6} key={riskCase.id}>
                <Box
                  sx={{
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    padding: '10px',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                    height: '100%',
                  }}
                >
                  <Typography variant="h6" component="div">
                    <Link
                      onClick={() => handleNavigation(riskCase.id)}
                      className="riskCase-name"
                    >
                      {riskCase.riskCaseName}
                    </Link>
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2">
                      <strong>Risk Case Owner:</strong> {riskCase.owner}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <strong>Date Created:</strong>{' '}
                      {new Date(riskCase.dateAdded).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <strong>Is Active:</strong>{' '}
                      {riskCase.isActive ? 'Yes' : 'No'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))
          ) : (
            <Typography variant="body2" align="center" sx={{ width: '100%', mt: 2 }}>
              There are no associated Risk Cases.
            </Typography>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PopupRiskCases;
