import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { LoadIndicator } from 'devextreme-react';
import VectorMap, { 
  Layer,
  Tooltip, 
} from 'devextreme-react/vector-map';

import DnbAPI from '../../../../api/dnb/dnbAPI';
import * as mapsData from 'devextreme-dist/js/vectormap-data/world.js';

const bounds = [-180, 85, 180, -60];

const formatRevenue = (revenue) => {
  if (revenue !== undefined) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(revenue);
  }
  return 'N/A';
};

const customizeTooltip = (arg) => {
  if (arg.layer.type === 'marker') {
    const name = arg.attribute('name');
    const address = arg.attribute('address');
    const city = arg.attribute('city');
    const country = arg.attribute('country');
    const description = arg.attribute('usSicV4Description') || 'N/A';
    const revenue = formatRevenue(arg.attribute('yearlyRevenue'));
    const duns =
      arg.attribute('duns') !== undefined
        ? `Duns: ${arg.attribute('duns')}`
        : 'Duns: N/A';

    return {
      text: `
      <strong>${name}</strong><br />
      ${address}<br />
      ${city}, ${country}<br />
      ${description}<br />
      ${revenue}<br />
      ${duns}`,
    };
  }
  return null;
};

const markerClick = ({ target, component }) => {
  if (target?.layer.type === 'marker') {
    component.center(target.coordinates()).zoomFactor(10);
  }
};

const FamilyTree = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [features, setFeatures] = useState([]);
  const { id } = useParams();
  const vectorMapRef = useRef(null);

  const fetchData = async () => {
    try {
      const data = await DnbAPI.getFamilyTreeDataById(id, false);
      const supplierMarker = data.supplierMarker;
      const additionalMarkers = data.additionalMarkers;

      const allMarkers = [
        {
          coordinates: supplierMarker.address?.dnBCoordinates
            ? [
                supplierMarker.address?.dnBCoordinates.longitude,
                supplierMarker.address?.dnBCoordinates.latitude,
              ]
            : null,
          attributes: {
            name: supplierMarker.companyName,
            address: supplierMarker.address?.street,
            city: supplierMarker.address?.localityName,
            country: supplierMarker.address?.countryName,
            usSicV4Description: supplierMarker?.usSicV4Description,
            yearlyRevenue: supplierMarker?.yearlyRevenue,
            duns: supplierMarker?.duns,
          },
        },
        ...additionalMarkers.map((marker) => ({
          coordinates: marker.address?.dnBCoordinates
            ? [
                marker.address?.dnBCoordinates.longitude,
                marker.address?.dnBCoordinates.latitude,
              ]
            : null,
          attributes: {
            name: marker.companyName,
            address: marker.address?.street,
            city: marker.address?.localityName,
            country: marker.address?.countryName,
            usSicV4Description: marker?.usSicV4Description,
            yearlyRevenue: marker?.yearlyRevenue,
            duns: marker?.duns,
          },
        })),
      ].filter((marker) => marker.coordinates !== null);

      setFeatures(allMarkers);

    } catch (error) {
      setError(error.message);

    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  if (error) return <div>No Data</div>;
  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadIndicator />
        </div>
      ) : (
        <React.Fragment>
          <VectorMap
            ref={vectorMapRef}
            id="vector-map"
            onClick={markerClick}
            bounds={bounds}
          >
            <Layer dataSource={mapsData.world} hoverEnabled={false} />
            <Layer dataSource={features} />
            <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
          </VectorMap>
        </React.Fragment>
      )}
    </>
  );
};

export default FamilyTree;
