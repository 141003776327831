import { LoadIndicator, TreeView } from "devextreme-react";
import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

interface TreeViewItem {
	id: number | string;
	text: string;
	expanded?: boolean;
	modelId?: number | string;
	type?: string;
	items?: TreeViewItem[];
	nonSelectable?: boolean;
}

const initialItems: TreeViewItem[] = [
	{
		id: 1,
		text: "Admin Settings",
		expanded: true,
		items: [],
	},
];

const renderTreeViewItem = (
	item: TreeViewItem,
	selectedItem: string | number | null
) => {
	const itemClass =
		item.modelId === selectedItem
			? "tree-view-item selected"
			: item.nonSelectable
			? "tree-view-item non-selectable"
			: item.items && item.items.length > 0
			? "tree-view-item title"
			: "tree-view-item value";
	return <div className={itemClass}>{item.text}</div>;
};

const DmsmsMenu: React.FC = () => {
	const [items, setItems] = useState<TreeViewItem[]>(initialItems);
	const [selectedItem, setSelectedItem] = useState<string | number | null>(
		null
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const navigate = useNavigate();
	const handleItemClick = (e: { itemData: TreeViewItem }) => {
		const itemData = e.itemData as TreeViewItem;
		const selectedId = itemData.modelId ?? itemData.id;
		setSelectedItem(selectedId);

		if (selectedId) {
			let url = "";

			switch (selectedId) {
				case 1:
					url = "/dmsms/admin";
					break;
			}

			return navigate(url);
		}
	};

	return (
		<div id="treeViewContainer" className="tree-view-container">
			{isLoading ? (
				<div className="spinner">
					<LoadIndicator />
				</div>
			) : (
				<TreeView
					items={items}
					width={300}
					itemRender={(item) =>
						renderTreeViewItem(item, selectedItem)
					}
					onItemClick={handleItemClick as any}
					selectByClick={true}
				/>
			)}
		</div>
	);
};

export default DmsmsMenu;
