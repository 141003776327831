import React from 'react';
import GeneralInformation from './GeneralInformation';

import CaseLog from './CaseLog';
import RiskCaseArticle from '../../../risk-Cases-tab/article/RiskCaseArticle'
import PartsAssemblies from './PartsAssemblies';
import { IRiskCase } from '../../../../components/risk-discovery/types/RiskCase';
import SelectBoxOption from '../../../../types/SelectBoxOption';
import MeasuresTable from './measurePopup';

interface GetSectionsProps {
  formData: IRiskCase;
  setFormData: React.Dispatch<React.SetStateAction<IRiskCase>>;
  owners: SelectBoxOption[] | null;
  subscribers: SelectBoxOption[] | null;
  suppliers: SelectBoxOption[] | null;
  kwm: SelectBoxOption[] | null;
  measures: any;
  handleEdit: (updatedMeasure: any) => void;
  articles: [];
}

const getSections = ({
  formData,
  setFormData,
  owners,
  subscribers,
  suppliers,
  kwm,
  measures,
  handleEdit,
  articles,
}: GetSectionsProps) => [
  {
    title: 'General Information',
    content: (
      <GeneralInformation
        formData={formData}
        setFormData={setFormData}
        owners={owners as SelectBoxOption[]}
        subscribers={subscribers as SelectBoxOption[]}
        suppliers={suppliers as SelectBoxOption[]}
        kwm={kwm as SelectBoxOption[]}
      />
    ),
  },
  {
    title: 'Measures',
    content: <MeasuresTable measures={measures || []} onEdit={handleEdit} />,
  },
  {
    title: 'Case Log',
    content: <CaseLog owners={owners ?? []} formData={formData} setFormData={setFormData} />,
  },
  {
    title: 'Articles',
    content: <RiskCaseArticle articles={articles} />,
  },
];

export default getSections;
