import React, { useCallback, useEffect, useState } from "react";
import { GeneralInformationViewModelDto, SelectBoxOptionDto, SubscriberViewModelDto } from "../../../../api/types/types";
import {
  TextField,
  Autocomplete,
  Grid,
  Chip,
  Select,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  debounce,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormStatus } from "../../models/FormStatus";
import { RiskCaseViewDropdownsData } from "../../models/RiskCaseViewDropdownsData";

interface GeneralInformationRiskCaseFormProps {
  initialData: GeneralInformationViewModelDto | null;
  dropdownData: RiskCaseViewDropdownsData | null;
  isLoading: boolean;
  onFormUpdate: (data: GeneralInformationViewModelDto, status: FormStatus) => void;
}

const GeneralInformationRiskCaseForm: React.FC<GeneralInformationRiskCaseFormProps> 
= ({ initialData, dropdownData, isLoading, onFormUpdate }) => {
  const [dropdowns, setDropdowns] = useState<RiskCaseViewDropdownsData>({
    keywordModels: dropdownData?.keywordModels || [],
    owners: dropdownData?.owners || [],
    subscribers: dropdownData?.subscribers || [],
    suppliers: dropdownData?.suppliers || [],
  })

  const [formData, setFormData] = useState<GeneralInformationViewModelDto>({
    riskCaseId: initialData?.riskCaseId ?? 0,
    riskCaseName: initialData?.riskCaseName ?? '',
    description: initialData?.description ?? '',
    keywordModels: initialData?.keywordModels ?? [],
    suppliers: initialData?.suppliers ?? [],
    subscribers: initialData?.subscribers ?? [],
    owner: initialData?.owner ?? {
      key: 'U:0',
      name: '',
    },
    isActive: initialData?.isActive ?? true,
    isDeleted: initialData?.isDeleted ?? false,
    dateAdded: initialData?.dateAdded ?? null,
    dateDeleted: initialData?.dateDeleted ?? null,
    dateUpdated: initialData?.dateUpdated ?? null,
  });

  const validateForm = (state: GeneralInformationViewModelDto): FormStatus => {
    const errors = []

    if(state.riskCaseName === ''){
      errors.push('Risk Case Name is required.')
    }

    if(state?.owner?.key == null || state.owner.key === 'U:0'){
      errors.push('Owner is required.')
    }

    return {
      isValid: errors.length === 0,
      errorMessages: errors
    };
  }

  const emitState = useCallback(
    debounce((formData: GeneralInformationViewModelDto) => {
    const formStatus = validateForm(formData);
    onFormUpdate(formData, formStatus)
  }, 300), [onFormUpdate])

  // Use useEffect to validate and emit the form data after the initial setState
  useEffect(() => {
    emitState(formData);
  }, []);

  const handleFieldChange = useCallback(
    (field: keyof GeneralInformationViewModelDto, value: any) => {
      const newState = {
        ...formData,
        [field]: value,
      }
      setFormData(newState);
      emitState(newState)
    },
    [setFormData, emitState, formData]
  );

  const handleMultipleChange = useCallback(
    (field: keyof GeneralInformationViewModelDto, value: any) => {
      const newState = {
        ...formData,
        [field]: value || [],
      }
      setFormData(newState);
      emitState(newState)
    },
    [setFormData, emitState, formData]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Risk Case Name"
            value={formData.riskCaseName || ''}
            onChange={(e) => handleFieldChange('riskCaseName', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            options={dropdowns.suppliers || []}
            getOptionLabel={(option) => option?.label || ''}
            value={formData.suppliers || []}
            onChange={(_, newValue) =>
              handleMultipleChange('suppliers', newValue || [])
            }
            renderInput={(params) => (
              <TextField {...params} label="Suppliers" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const {key, ...tagProps} = getTagProps({ index });
                return <Chip key={key} {...tagProps} label={option?.label || ''} />;
              })
            }
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Select Owner</InputLabel>
            <Select
              value={formData.owner?.key || ''}
              label="Select Owner"
              onChange={(e) => {
                const selectedOwner = dropdowns.owners.find(
                  (owner) => owner.key === e.target.value
                );
                handleFieldChange('owner', selectedOwner);
              }}
            >
              {dropdowns.owners.map((owner) => (
                <MenuItem key={owner.key} value={owner.key}>
                  {owner.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            options={dropdowns.keywordModels || []}
            getOptionLabel={(option) => option.label || ''}
            value={formData.keywordModels || []}
            onChange={(_, newValue) =>
              handleMultipleChange('keywordModels', newValue)
            }
            renderInput={(params) => (
              <TextField {...params} label="Keyword Model" />
            )}
            renderTags={(value: SelectBoxOptionDto[], getTagProps) =>
              value.map((option: SelectBoxOptionDto, index: number) => {
                const {key, ...tagProps} = getTagProps({ index });
                return <Chip key={key} {...tagProps} label={option.label} />
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            label="Date Created"
            value={formData.dateAdded ? new Date(formData.dateAdded) : null}
            onChange={(newValue) => handleFieldChange('dateAdded', newValue)}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                checked={formData.isActive}
                onChange={(e) =>
                  handleFieldChange('isActive', e.target.checked)
                }
              />
            }
            label="Is Active?"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Description"
            value={formData.description || ''}
            onChange={(e) => handleFieldChange('description', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={dropdowns.subscribers || []}
            getOptionLabel={(option: SubscriberViewModelDto) => option?.name || ''}
            value={(formData.subscribers as SubscriberViewModelDto[]) || []}
            onChange={(_, newValue) =>
              handleMultipleChange('subscribers', newValue as SubscriberViewModelDto[])
            }
            renderInput={(params) => (
              <TextField {...params} label="Subscribers" />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return <Chip key={key} {...tagProps} label={option.name} />;
              })
            }
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default GeneralInformationRiskCaseForm;