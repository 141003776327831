// @ts-ignore: Implicit any module
import httpClient from "../configs/axiosConfig";
// @ts-ignore: Implicit any module
import config from "../../config";

const { NSN_SERVICE_URL } = config;


const Verify = {

  getCageCode: async function (cageCodeId: number) {
		const url = `${NSN_SERVICE_URL}/Verify/CageCode/${cageCodeId}`;
		const { data } = await httpClient.get(url);
		return data.result ;
	},

};

export default Verify;
