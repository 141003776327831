import React from "react";
import { TextField, FormControl, InputLabel, Box } from "@mui/material";
import { labelStyles, textAreaStyles } from "../styles";
import HelpIcon from "../help/HelpIcon.jsx";

export default function AltruityTextArea({
	label,
	width,
	onChange,
	helpConent,
	inputRef,
	rows,
	...props
}) {
	return (
		<FormControl className="altruity-field" style={{ width: width }}>
			{helpConent ? (
				<Box display="flex">
					<InputLabel shrink sx={labelStyles}>
						{label}
					</InputLabel>
					<HelpIcon label={label}>{helpConent}</HelpIcon>
				</Box>
			) : (
				<InputLabel shrink sx={labelStyles}>
					{label}
				</InputLabel>
			)}
			<TextField
				{...props}
				multiline
				rows={rows ?? 4}
				onChange={onChange}
				InputProps={{
					style: inputRef?.current?.disabled
						? textAreaStyles.disabled
						: textAreaStyles.normal,
				}}
				inputRef={inputRef}
			/>
		</FormControl>
	);
}
