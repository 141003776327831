// @ts-ignore: Implicit any module
import httpClient from "../configs/axiosConfig";
// @ts-ignore: Implicit any module
import config from "../../config";
import { StringKeySelectBoxOptionDto } from "../../api/types/types";

const { ALTRUITY_API_URL } = config;

const Subscribers = {
  getAllSubscribersByKeywordModel: async function (keywordModelId: number) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/KeywordModels/GetAllSubscribersByKeywordModel/${keywordModelId}`
    );
    return data.result;
  },

  getSubscribersDropdown: async function () {
    const { data } = await httpClient.get<{result: StringKeySelectBoxOptionDto[]}>(
      `${ALTRUITY_API_URL}/KeywordModels/GetSubscribersDropdown`
    );
    return data.result;
  },
};

export default Subscribers;
