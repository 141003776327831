import React from "react";
import {
	SupplierPage,
	DashboardPage,
	DiscoveryPage,
	RiskModelsPage,
	RiskCase,
	CurrentRiskCases,
	RiskMeasuresAdmin,
	SupplierSettingsAdmin,
	NsnPage,
	NsnSearchResultsPage,
	NsnDetailPage,
	NsnBatchPage,
	DmsmsAdminSettingsPage,
	Users,
	Roles,
} from "./pages";
import { withNavigationWatcher } from "./contexts/navigation";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ element: Component }) => {
	const { user } = useSelector((state) => state.auth);

	return user ? <Component /> : <Navigate to="/login" />;
};

const routes = [
	{
		path: "/scrm/suppliers/:id",
		element: SupplierPage,
	},
	{
		path: "/scrm/dashboard",
		element: DashboardPage,
	},
	{
		path: "/scrm/discovery/kwm/:id",
		element: DiscoveryPage,
	},
	{
		path: "/scrm/discovery/supplier/:id",
		element: DiscoveryPage,
	},
	{
		path: "/scrm/discovery/archived-supplier/:id",
		element: DiscoveryPage,
	},
	{
		path: "/scrm/discovery/archived-keyword/:id",
		element: DiscoveryPage,
	},
	{
		path: "/scrm/risk-models/:id",
		element: RiskModelsPage,
	},
	{
		path: "/scrm/risk-cases/current/",
		element: () => <CurrentRiskCases />,
	},
	{
		path: "/scrm/risk-cases/kwm/:id",
		element: () => <RiskCase />,
	},
	{
		path: "/scrm/risk-cases/supplier/:id",
		element: () => <RiskCase />,
	},
	{
		path: "/scrm/risk-cases/archived-kwm/:id",
		element: () => <RiskCase />,
	},
	{
		path: "/scrm/risk-cases/archived-supplier/:id",
		element: () => <RiskCase />,
	},
	{
		path: "/scrm/admin/risk-measures",
		element: () => <RiskMeasuresAdmin />,
	},
	{
		path: "/scrm/admin/supplier-settings",
		element: () => <SupplierSettingsAdmin />,
	},
	{
		path: "/dmsms/admin",
		element: DmsmsAdminSettingsPage,
	},
	{
		path: "/nsn/search",
		element: NsnPage,
	},
	{
		path: "/nsn/results",
		element: NsnSearchResultsPage,
	},
	{
		path: "/nsn/details",
		element: NsnDetailPage,
	},
	{
		path: "/nsn/batch",
		element: NsnBatchPage,
	},
	{
		path: "/user-admin/users",
		element: () => <Users/>,
	},
	{
		path: "/user-admin/roles",
		element: () => <Roles/>,
	},
	{
		path: "/user-admin/activities",
		element: () => <h4>Activities Page</h4>,
	},
];

const protectedRoutes = routes.map((route) => {
	return {
		...route,
		element: withNavigationWatcher(() => (
			<ProtectedRoute element={route.element} />
		)),
	};
});

export default protectedRoutes;
