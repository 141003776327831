import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { MeasuresViewModelDto, RiskMeasureViewModelDto, SelectBoxOptionDto } from "../../../../api/types/types";
import { FormStatus } from "../../models/FormStatus";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  TextField,
  Button,
  SelectChangeEvent,
  Box,
  debounce,
} from '@mui/material';


interface MeasuresFormProps {
  initialData: MeasuresViewModelDto | null;
  isLoading: boolean;
  onFormUpdate: (data: MeasuresViewModelDto, status: FormStatus) => void;
}

const MeasuresForm: React.FC<MeasuresFormProps> = (
  {initialData, isLoading, onFormUpdate}
) => {

  const [formData, setFormData] = useState<MeasuresViewModelDto>({
    riskMeasures: initialData?.riskMeasures ?? []
  })
  const [editRowId, setEditRowId] = useState<number | null>(null);

  const validateForm = (state: MeasuresViewModelDto): FormStatus => {
    const errors: string[] = []

    const nullRequiredMeasures = state.riskMeasures
      .filter(rm => rm.isRequired && rm.selectedLevelId == null)
      .map(rm => rm.riskMeasureName)

    if(nullRequiredMeasures.length > 0){
      nullRequiredMeasures.forEach(mName => errors.push(`Measure ${mName} is required.`))
    }

    return {
      isValid: errors.length === 0,
      errorMessages: errors
    };
  }

  // Emit state with debounce to reduce frequent updates on fast input changes
  const emitState = useCallback(
    debounce((updatedFormData: MeasuresViewModelDto) => {
      const formStatus = validateForm(updatedFormData);
      onFormUpdate(updatedFormData, formStatus);
    }, 300),
    [onFormUpdate]
  );

  // Use useEffect to validate and emit the form data after the initial setState
  useEffect(() => {
    emitState(formData);
  }, []);

  const sortRiskMeasureLevels = (levels: SelectBoxOptionDto[]) => {
    return levels.sort((a, b) => {
      return a.id - b.id // Ids will always match the level id
    });
  };


  const updateRiskMeasure = (rowId: number, updater: (measure: any) => void) => {
    setFormData(prevData => {
      const riskMeasures = prevData.riskMeasures.map(rm => {
        if (rm.riskMeasureId === rowId) {
          const updatedMeasure = { ...rm };
          updater(updatedMeasure); // Apply custom updates
          return updatedMeasure;
        }
        return rm;
      });

      const newState = { ...prevData, riskMeasures };
      emitState(newState); // Emit new state after update
      return newState;
    });
  };

  const handleSeveritySelection = useCallback((event: SelectChangeEvent<number>, rowId: number) => {
    const selectedLevelId = Number(event.target.value);
    updateRiskMeasure(rowId, (measure) => {
      measure.selectedLevelId = selectedLevelId;
    });
  }, []);

  const handleCommentChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rowId: number) => {
    const newComment = event.target.value;
    updateRiskMeasure(rowId, (measure) => {
      measure.comments = newComment;
    });
  }, []);

  const handleSaveClick = useCallback((row: RiskMeasureViewModelDto) => {
    setEditRowId(null);
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="30%">Measure</TableCell>
            <TableCell width="30%">Severity</TableCell>
            <TableCell width="30%">Comments</TableCell>
            <TableCell width="10%">Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formData.riskMeasures.map((row) => (
            <TableRow key={row.riskMeasureId}>
              <TableCell>
                {row?.isRequired && <span style={{ color: 'red' }}>* </span>}
                {row.riskMeasureName}
              </TableCell>
              <TableCell>
                {editRowId === row.riskMeasureId ? (
                  <Box sx={{ minWidth: 120, maxWidth: '100%' }}>
                    <Select
                      value={row.selectedLevelId || ''}
                      onChange={(e) => handleSeveritySelection(e, row.riskMeasureId)}
                      fullWidth
                      size="small"
                      sx={{
                        fontSize: '0.875rem',
                      }}
                    >
                      {sortRiskMeasureLevels(row.availableRiskMeasureLevels).map((level) => (
                        <MenuItem key={level.id} value={level.id} sx={{ fontSize: '0.875rem', padding: '6px 16px' }}>
                          {level.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                ) : (
                  row.selectedLevelId 
                    ? row.availableRiskMeasureLevels.find(levels => levels.id === row.selectedLevelId)?.label
                    : 'No severity selected'
                )}
              </TableCell>
              <TableCell>
                {editRowId === row.riskMeasureId ? (
                  <TextField
                    value={row.comments || ''}
                    onChange={(e) => handleCommentChange(e, row.riskMeasureId)}
                    fullWidth
                    size="small"
                    multiline
                    maxRows={4}
                  />
                ) : (
                  row.comments || ''
                )}
              </TableCell>
              <TableCell>
                {editRowId === row.riskMeasureId ? (
                  <Button onClick={() => handleSaveClick(row)}>
                    Save
                  </Button>
                ) : (
                  <Button onClick={() => setEditRowId(row.riskMeasureId)}>
                    Edit
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MeasuresForm;