import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  Grid,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  SelectChangeEvent,
  Tooltip,
  IconButton,
  CircularProgress,
  TextField,
  Switch,
  Button,
  Snackbar,
  Alert,
  SnackbarCloseReason,
} from "@mui/material";
import RoleRow from "./RolesRow/RoleRow";
import "./RolesTable.scss";
import UserAdminAPI from "../../../api/userAdmin/userAdminAPI";
import {
  AppDto,
  AppResourceDto,
  CreateRoleDto,
  RoleDto,
  RoleResourceDto,
} from "../../../api/types/types";
import AddIcon from "@mui/icons-material/Add";
import { SnackbarProps } from "../../../types/Snackbar";

const defaultNewRole: CreateRoleDto = {
  id: 0,
  roleName: "",
  isActive: true,
  appId: 0,
  createdByd: null,
  updatedById: null,
};

const RolesTable: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [apps, setApps] = useState<AppDto[]>([]);
  const [selectedApp, setSelectedApp] = useState<number>(0);
  const [roles, setRoles] = useState<RoleDto[]>([]);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [newRole, setNewRole] = useState<CreateRoleDto>({ ...defaultNewRole });
  const [snackbar, setSnackbar] = useState<SnackbarProps>();
  const [appResources, setAppResources] = useState<AppResourceDto[]>([]);
  const [roleResources, setRoleResources] = useState<RoleResourceDto[]>([]);

  const handleAppChange = (event: SelectChangeEvent) => {
    setSelectedApp(parseInt(event.target.value));
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRole((prevNewRole) => ({
      ...prevNewRole,
      isActive: event.target.checked,
    }));
  };

  useEffect(() => {
    UserAdminAPI.getAllApp().then((response: AppDto[]) => {
      setApps(response);
      setSelectedApp(response[0].id);
    });
  }, []);

  const getRoles = async () => {
    const response = await UserAdminAPI.getRoleAppById(selectedApp);
    setRoles(response);
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      await Promise.all([
        getRoles(),
        UserAdminAPI.getAllAppResourcesByAppId(selectedApp).then((response) => {
          setAppResources(response);
        }),
        UserAdminAPI.getAllRoleResourcesByAppId(selectedApp).then(
          (response) => {
            setRoleResources(response);
          }
        ),
      ]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedApp) return;

    fetchData();
  }, [selectedApp]);

  const handleCreateRole = async () => {
    try {
      setLoading(true);
      const response = await UserAdminAPI.addRole(newRole);
      getRoles();
      setIsAdding(false);

      setSnackbar({
        open: true,
        severity: "success",
        message: response.message.length ? response.message : "Entity created.",
      });
    } catch (e: any) {
      const errorMessage = e.response?.data?.message || "An error occurred";

      setSnackbar({
        open: true,
        severity: "error",
        message: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  const closeSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar((prev: any) => ({
      ...prev,
      open: false,
    }));
  };

  const mapRoleDtoToCreateRoleDto = (role: RoleDto): CreateRoleDto => {
    return {
      id: role.id,
      roleName: role.roleName,
      isActive: role.isActive,
      appId: role.appId,
      createdByd: role.createdById,
      updatedById: role.updatedById,
    };
  };

  const handleUpdateRole = async (updatedRole: RoleDto) => {
    try {
      setRoles((prevRoles) =>
        prevRoles.map((role) =>
          role.id === updatedRole.id ? updatedRole : role
        )
      );

      const updatedRoleBody = mapRoleDtoToCreateRoleDto(updatedRole);

      setLoading(true);
      const response = await UserAdminAPI.updateRole(updatedRoleBody);

      setSnackbar({
        open: true,
        severity: "success",
        message: response.message.length ? response.message : "Entity updated.",
      });
    } catch (e: any) {
      const errorMessage = e.response?.data?.message || "An error occurred";

      setSnackbar({
        open: true,
        severity: "error",
        message: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Snackbar
        open={snackbar?.open}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={snackbar?.severity === "error" ? null : 5000}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar?.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
      <Box className="app-selector__container">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper className="app-selector__paper">
              <Typography variant="h6" className="app-selector__title">
                Select Application
              </Typography>
              <Select
                value={selectedApp.toString()}
                onChange={handleAppChange}
                className="app-selector__select"
                size="small"
                disabled={isAdding}
              >
                {apps.map((app) => (
                  <MenuItem key={app.id} value={app.id}>
                    {app.appName}
                  </MenuItem>
                ))}
              </Select>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className="app-selector__paper">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" className="app-selector__title">
                  Roles - {apps.find((app) => app.id === selectedApp)?.appName}
                </Typography>
                <Tooltip title="Add a row">
                  <IconButton
                    onClick={() => {
                      setIsAdding(true);
                      setNewRole({ ...defaultNewRole, appId: selectedApp });
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell width={"1%"} />
                      <TableCell>Name</TableCell>
                      <TableCell>Active?</TableCell>
                      {isAdding && <TableCell>Actions</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isAdding && (
                      <TableRow className="roles-table-new-row">
                        <TableCell></TableCell>
                        <TableCell>
                          <TextField
                            value={newRole.roleName}
                            onChange={(e) =>
                              setNewRole((prevNewRole) => ({
                                ...prevNewRole,
                                roleName: e.target.value,
                              }))
                            }
                            fullWidth
                            placeholder="Enter role name"
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={newRole.isActive}
                            onChange={handleSwitchChange}
                          />
                        </TableCell>
                        <TableCell>
                          <Button onClick={handleCreateRole}>Save</Button>
                          <Button
                            onClick={() => {
                              setIsAdding(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}
                    {roles.length
                      ? roles.map((role) => (
                          <RoleRow
                            key={role.id}
                            role={role}
                            appResources={appResources}
                            roleResources={roleResources}
                            setRoleResources={setRoleResources}
                            onUpdateRole={handleUpdateRole}
                            isAdding={isAdding}
                          />
                        ))
                      : !isAdding && (
                          <TableRow>
                            <TableCell
                              colSpan={12}
                              sx={{ textAlign: "center", height: "50px" }}
                            >
                              {loading ? <CircularProgress /> : "No Data Row"}
                            </TableCell>
                          </TableRow>
                        )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RolesTable;
