import React from "react";
import {
	FormControl,
	InputLabel,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import { labelStyles } from "../styles";

export interface AltruityToggleButtonProps {
	name: string;
	value: any;
}

interface AltruityToggleProps {
	name: string;
	label?: string | null;
	buttons: AltruityToggleButtonProps[];
	value: any;
	defaultValue?: any | null;
	onChange: (field: string, e: any) => void;
}

export default function AltruityToggleButtons({
	name,
	label,
	buttons,
	value,
	defaultValue,
	onChange,
}: AltruityToggleProps) {
	return (
		<FormControl fullWidth>
			{label ? (
				<InputLabel shrink sx={labelStyles}>
					{label}
				</InputLabel>
			) : null}
			<ToggleButtonGroup
				color="primary"
				value={value}
				exclusive
				defaultValue={defaultValue}
				onChange={(event, newValue) => {
					onChange(name, newValue);
				}}
				aria-label="Platform"
			>
				{buttons.map((btn: AltruityToggleButtonProps) => (
					<ToggleButton key={btn.value} value={btn.value}>
						{btn.name}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</FormControl>
	);
}
