import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	LinearProgress,
	SnackbarCloseReason,
} from "@mui/material";
import {
	AltruityTagBox,
	AltruityToggleButtons,
	AltruityRadioGroup,
	AltruitySelectField,
	SelectFieldOption,
	RadioOption,
	TagBoxOption,
	ValidationSnackbar,
} from "../../components/form";
import { AltruityToggleButtonProps } from "../../components/form/toggle/AltruityToggleButtons";
import {
	isNullOrUndefined,
	isNullOrWhitespace,
} from "../../utils/validation-helpers";
import { DmsmsAdminSettingsDto, ResponseDto } from "../../api/types/types";
import DmsmsApi from "../../api/dmsms/dmsmsAPI";
import PermissionsApi from "../../api/permissions/permissionsAPI";
import { setRoles, setUsers, Role, User } from "../../slices/permissionsSlice";
import { useDispatch, useSelector } from "react-redux";

const plj2LoadOptions: SelectFieldOption[] = [
	{ text: "Daily", value: 0 },
	{ text: "Weekly", value: 1 },
	{ text: "Monthly", value: 2 },
];

const gidepFrequency: SelectFieldOption[] = [
	{ text: "Weekly on Mondays", value: 0 },
	{ text: "Monthly on first day of month", value: 1 },
	{ text: "Quarterly on first day of quarter", value: 2 },
];

const yessNoOptions: RadioOption[] = [
	{ text: "Yes", value: true },
	{ text: "No", value: false },
];

//const users: TagBoxOption[] = [{ text: "Chris Underwood", value: 0 }];
//const roles: TagBoxOption[] = [{ text: "Admin", value: 0 }];

const includeExcludeButtons: AltruityToggleButtonProps[] = [
	{
		name: "Include",
		value: true,
	},
	{
		name: "Exclude",
		value: false,
	},
];

const rowSpacing = 2;
const columnSpacing = 4;

export default function DmsmsAdminSettingsPage() {
	const dispatch = useDispatch();

	const users: TagBoxOption[] = useSelector(
		(state: any) => state.permission.users
	).map((u: User) => ({ text: u.fullName, value: u.id }));

	const roles: TagBoxOption[] = useSelector(
		(state: any) => state.permission.roles
	).map((u: Role) => ({ text: u.name, value: u.id }));

	const [formData, setFormData] = useState<DmsmsAdminSettingsDto>({
		smrOption: true,
		smrValues: [],
		essentialityOption: true,
		essentialityValues: [],
		fscnOption: true,
		fscnValues: [],
		lruOption: true,
		frequencyOfPlj2Loads: 0,
		initiateGidepScanOnLoadedParts: true,
		automaticallyRemoveParts: true,
		usersToNotify: [],
		rolesToNotify: [],
		frequencyOfGidepScanNonTaggedParts: 0,
		frequencyOfGidepScanTaggedParts: 0,
	});

	const [sendingSate, setSendingState] = useState({
		isLoadingData: false,
		isSaving: false,
		isRunningPlj2Load: false,
		isRunningGidepScan: false,
	});

	// Validation snackbar state
	const [snackBarState, setSnackBarState] = useState({
		open: false,
		message: "",
		severity: "error",
	});

	/**
	 * Updates Users, Roles
	 */
	useEffect(() => {
		/**
		 * Retrieve users from Permissions API and updates slice.
		 */
		const fetchUsers = async () => {
			try {
				const data = await PermissionsApi.getAllUsers(true);

				if (data.isSuccess) {
					const users: User[] = data.result.map((u) => ({
						id: u.id,
						fullName: u.fullName,
					}));
					dispatch(setUsers(users));
				} else {
					showError(data.message);
					console.error(data.message);
				}
			} catch (error) {
				showError("Error Loading Users", error);
				console.error(error);
			}
		};

		/**
		 * Retrieve role from Permissions API and updates slice.
		 */
		const fetchRoles = async () => {
			try {
				const data = await PermissionsApi.getAllRoles(true);

				if (data.isSuccess) {
					const roles: Role[] = data.result.map((u) => ({
						id: u.id,
						name: u.roleName,
					}));
					dispatch(setRoles(roles));
				} else {
					showError(data.message);
					console.error(data.message);
				}
			} catch (error) {
				showError("Error Loading Roles", error);
				console.error(error);
			}
		};

		fetchUsers();
		fetchRoles();
	}, [dispatch]);

	/**
	 * Load previously saved form data.
	 */
	useEffect(() => {
		/**
		 * / Fetch new data on initial component load
		 */
		async function fetchData() {
			setSendingState((oldState) => ({
				...oldState,
				isLoadingData: true,
			}));

			try {
				// Get paginated data from the server.
				const response: ResponseDto<DmsmsAdminSettingsDto> =
					await DmsmsApi.getSettings();

				if (response.isSuccess === true) {
					setSendingState((oldState) => ({
						...oldState,
						isLoadingData: false,
					}));
					setFormData(response.result);
				} else {
					setSendingState((oldState) => ({
						...oldState,
						isLoadingData: false,
					}));
					showError(response.message);
				}
			} catch (error) {
				setSendingState((oldState) => ({
					...oldState,
					isLoadingData: false,
				}));
				showError("Error sending query!", error);
			}
		}

		fetchData();
	}, []);

	/**
	 * Closes the validation SnackBar
	 * @param {*} event
	 * @param {*} reason
	 * @returns
	 */
	const handleSnackBarClose = (
		event: React.SyntheticEvent | Event,
		reason?: SnackbarCloseReason
	) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarState((prevState) => ({
			...prevState,
			open: false,
			message: "",
		}));
	};

	/**
	 * Updates form data.
	 * @param field
	 * @param e
	 */
	function handleFieldChange(field: string, e: any) {
		setFormData((prevData) => {
			var newData = { ...prevData };

			switch (field) {
				case "smrOption":
					newData.smrOption = e;
					break;
				case "smrValues":
					{
						const { value } = e.target;
						if (validateSmrCode(value)) {
							const newValue = value.toUpperCase();
							if (newData.smrValues.indexOf(newValue) < 0) {
								newData.smrValues.push(newValue);
							}
						}
					}
					break;
				case "essentialityOption":
					newData.essentialityOption = e;
					break;
				case "essentialityValues":
					{
						const { value } = e.target;
						if (validateEssentialityCode(value)) {
							const newValue = value.toUpperCase();
							if (
								newData.essentialityValues.indexOf(newValue) < 0
							) {
								newData.essentialityValues.push(newValue);
							}
						}
					}
					break;
				case "fscnOption":
					newData.fscnOption = e;
					break;
				case "fscnValues":
					{
						const { value } = e.target;
						if (validateFscnCode(value)) {
							const newValue = value.toUpperCase();
							if (newData.fscnValues.indexOf(newValue) < 0) {
								newData.fscnValues.push(newValue);
							}
						}
					}
					break;
				case "lruOption":
					newData.lruOption = e;
					break;
				case "frequencyOfPlj2Loads":
					{
						const { value } = e.target;
						newData.frequencyOfPlj2Loads = value;
					}
					break;
				case "initiateGidepScanOnLoadedParts":
					{
						const { value } = e.target;
						newData.initiateGidepScanOnLoadedParts = value;
					}
					break;
				case "automaticallyRemoveParts":
					const { value } = e.target;
					newData.automaticallyRemoveParts = value;
					break;
				case "usersToNotify":
					{
						const { value } = e.target;
						addToArray(newData.usersToNotify, value);
					}
					break;
				case "rolesToNotify":
					{
						const { value } = e.target;
						addToArray(newData.rolesToNotify, value);
					}
					break;
				case "frequencyOfGidepScanNonTaggedParts":
					{
						const { value } = e.target;
						newData.frequencyOfGidepScanNonTaggedParts = value;
					}
					break;
				case "frequencyOfGidepScanTaggedParts":
					{
						const { value } = e.target;
						newData.frequencyOfGidepScanTaggedParts = value;
					}
					break;
			}

			return newData;
		});
	}

	function addToArray(array: number[], value: any) {
		if (!isNullOrUndefined(value)) {
			if (Array.isArray(value)) {
				for (let i = 0; i < value.length; i++) {
					const v = value[i];
					const numValue = parseInt(v);
					if (array.indexOf(numValue) < 0) {
						array.push(numValue);
					}
				}
			} else {
				if (array.indexOf(value) < 0) {
					array.push(value);
				}
			}
		}
	}

	/**
	 * Validation for SMR codes
	 * @param value
	 * @returns
	 */
	function validateSmrCode(value: string): boolean {
		let regex = /^[a-zA-Z]+$/;

		if (isNullOrWhitespace(value)) {
			return false;
		}

		if (value.length !== 5) {
			return false;
		}

		if (!regex.test(value)) {
			return false;
		}

		return true;
	}

	/**
	 * Validation for Essentiality codes
	 * @param value
	 * @returns
	 */
	function validateEssentialityCode(value: string): boolean {
		if (isNullOrWhitespace(value)) {
			return false;
		}

		if (value.length !== 1) {
			return false;
		}

		return true;
	}

	/**
	 * Validation for FSCN codes
	 * @param value
	 * @returns
	 */
	function validateFscnCode(value: string): boolean {
		let regex = /^[0-9]+$/;

		if (isNullOrWhitespace(value)) {
			return false;
		}

		if (value.length !== 4) {
			return false;
		}

		if (!regex.test(value)) {
			return false;
		}

		return true;
	}

	/**
	 * Tab Box value delete function
	 * @param field
	 * @param value
	 */
	function handleChipDelete(field: string, value: any) {
		setFormData((prevData) => {
			const newData = { ...prevData };
			let prop = field as keyof typeof prevData;
			const arrValues = newData[prop] as any[];
			var newValues: any[] = [];

			if (arrValues.indexOf(value) > -1) {
				newValues = arrValues.filter((smr) => smr !== value);
			}

			return { ...prevData, [prop]: newValues };
		});
	}

	/**
	 * Displays error message
	 * @param message
	 * @param error
	 */
	const showError = (message: string, error?: any) => {
		if (error) {
			console.error(message, error);
		}
		setSnackBarState({
			open: true,
			message: message,
			severity: "error",
		});
	};
	/**
	 * Sends request to save settings to the DMSMS API.
	 */
	async function handleSubmit() {
		setSendingState((prevState) => ({ ...prevState, isSaving: true }));
		try {
			var response = await DmsmsApi.saveSettings(formData);

			if (response.isSuccess) {
				setSnackBarState({
					open: true,
					message: "Settings Saved!",
					severity: "success",
				});
			} else {
				showError(response.message);
			}
		} catch (ex) {
			showError("Error saving!", ex);
		}

		setSendingState((prevState) => ({ ...prevState, isSaving: false }));
	}

	function handleRunPlj2() {
		setSnackBarState((prevState) => ({
			...prevState,
			open: true,
			severity: "info",
			message: "To Be Implemented",
		}));
	}

	function handleRunGidep() {
		setSnackBarState((prevState) => ({
			...prevState,
			open: true,
			severity: "info",
			message: "To Be Implemented",
		}));
	}

	return (
		<>
			<div className="page-container">
				<h4 className="page-title">Admin Settings</h4>

				{sendingSate.isLoadingData ? (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						sx={{ width: "100%" }}
					>
						<LinearProgress sx={{ width: "100%" }} />
					</Box>
				) : null}
				<Grid container rowSpacing={rowSpacing}>
					<Grid item container>
						<Grid item xs={2}>
							<AltruityToggleButtons
								name="smrOption"
								label="SMR Codes"
								buttons={includeExcludeButtons}
								onChange={handleFieldChange}
								value={formData.smrOption}
							/>
						</Grid>
						<Grid item xs={10} sx={{ position: "relative" }}>
							<AltruityTagBox
								autoComplete
								name="smrValues"
								label={null}
								values={formData.smrValues}
								onChange={handleFieldChange}
								onDeleteOption={handleChipDelete}
								formControlSx={{
									position: "absolute",
									bottom: "0",
									width: "100%",
								}}
							/>
						</Grid>
					</Grid>
					<Grid item container>
						<Grid item xs={2}>
							<AltruityToggleButtons
								name="essentialityOption"
								label="Essentiality Codes"
								buttons={includeExcludeButtons}
								onChange={handleFieldChange}
								value={formData.essentialityOption}
							/>
						</Grid>
						<Grid item xs={10} sx={{ position: "relative" }}>
							<AltruityTagBox
								autoComplete
								name="essentialityValues"
								label={null}
								values={formData.essentialityValues}
								onChange={handleFieldChange}
								onDeleteOption={handleChipDelete}
								formControlSx={{
									position: "absolute",
									bottom: "0",
									width: "100%",
								}}
							/>
						</Grid>
					</Grid>
					<Grid item container>
						<Grid item xs={2}>
							<AltruityToggleButtons
								name="fscnOption"
								label="FSCN"
								buttons={includeExcludeButtons}
								onChange={handleFieldChange}
								value={formData.fscnOption}
							/>
						</Grid>
						<Grid item xs={10} sx={{ position: "relative" }}>
							<AltruityTagBox
								autoComplete
								name="fscnValues"
								label={null}
								values={formData.fscnValues}
								onChange={handleFieldChange}
								onDeleteOption={handleChipDelete}
								formControlSx={{
									position: "absolute",
									bottom: "0",
									width: "100%",
								}}
							/>
						</Grid>
					</Grid>
					<Grid item container>
						<Grid item xs={2}>
							<AltruityToggleButtons
								name="lruOption"
								label="LRU"
								buttons={includeExcludeButtons}
								onChange={handleFieldChange}
								value={formData.lruOption}
							/>
						</Grid>
					</Grid>
					<Grid item container columnSpacing={columnSpacing}>
						<Grid item xs={6}>
							<AltruitySelectField
								name="frequencyOfPlj2Loads"
								label="Frequency of Incremental PLJ2 Loads"
								options={plj2LoadOptions}
								value={formData.frequencyOfPlj2Loads}
								onChange={handleFieldChange}
							/>
						</Grid>
						<Grid item xs={6}>
							<AltruityRadioGroup
								name="initiateGidepScanOnLoadedParts"
								label="Initiate GIDEP Scan on Incrementally Loaded Parts?"
								onChange={handleFieldChange}
								options={yessNoOptions}
								value={formData.initiateGidepScanOnLoadedParts}
								row
							/>
						</Grid>
					</Grid>
					<Grid item container columnSpacing={columnSpacing}>
						<Grid item xs={6}>
							<AltruityRadioGroup
								name="automaticallyRemoveParts"
								label="IAutomatically Remove Parts No Longer in PLJ2?"
								onChange={handleFieldChange}
								options={yessNoOptions}
								value={formData.automaticallyRemoveParts}
								row
							/>
						</Grid>
					</Grid>
					<Grid item container columnSpacing={columnSpacing}>
						<Grid item xs={6}>
							<AltruityTagBox
								name="usersToNotify"
								label="Users to Notify"
								placeholder="Select..."
								values={formData.usersToNotify}
								onChange={handleFieldChange}
								onDeleteOption={handleChipDelete}
								options={users}
							/>
						</Grid>{" "}
						<Grid item xs={6}>
							<AltruityTagBox
								name="rolesToNotify"
								label="Roles to Notify"
								placeholder="Select..."
								values={formData.rolesToNotify}
								onChange={handleFieldChange}
								onDeleteOption={handleChipDelete}
								options={roles}
							/>
						</Grid>
					</Grid>{" "}
					<Grid item container columnSpacing={columnSpacing}>
						<Grid item xs={6}>
							<AltruitySelectField
								name="frequencyOfGidepScanNonTaggedParts"
								label="Frequency of GIDEP scan on Non-Tagged Parts"
								value={
									formData.frequencyOfGidepScanNonTaggedParts
								}
								onChange={handleFieldChange}
								options={gidepFrequency}
							/>
						</Grid>
						<Grid item xs={6}>
							<AltruitySelectField
								name="frequencyOfGidepScanTaggedParts"
								label="Frequency of GIDEP scan on Tagged Parts"
								value={formData.frequencyOfGidepScanTaggedParts}
								onChange={handleFieldChange}
								options={gidepFrequency}
							/>
						</Grid>
					</Grid>
					<Grid item container columnSpacing={columnSpacing}>
						<Grid item xs={6}>
							<Button
								variant="contained"
								onClick={handleSubmit}
								disabled={sendingSate.isSaving}
								endIcon={
									sendingSate.isSaving ? (
										<CircularProgress size={16} />
									) : null
								}
							>
								Save
							</Button>
						</Grid>
					</Grid>{" "}
					<Grid
						item
						container
						columnSpacing={columnSpacing}
						style={{ marginTop: "20px" }}
					>
						<Grid item xs={6}>
							<Button
								variant="contained"
								onClick={handleRunPlj2}
								disabled={sendingSate.isRunningPlj2Load}
								endIcon={
									sendingSate.isRunningPlj2Load ? (
										<CircularProgress size={16} />
									) : null
								}
							>
								Run Incremental PLJ2 Load
							</Button>
						</Grid>
					</Grid>{" "}
					<Grid item container columnSpacing={columnSpacing}>
						<Grid item xs={6}>
							<Button
								variant="contained"
								onClick={handleRunGidep}
								disabled={sendingSate.isRunningGidepScan}
								endIcon={
									sendingSate.isRunningGidepScan ? (
										<CircularProgress size={16} />
									) : null
								}
							>
								Run GIDEP Scan
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</div>
			<ValidationSnackbar
				open={snackBarState.open}
				message={snackBarState.message}
				onClose={handleSnackBarClose}
				severity={snackBarState.severity}
			/>
		</>
	);
}
