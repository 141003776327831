import React, { useEffect, useState } from 'react';
import { SelectBox as SelectBoxComponent } from 'devextreme-react/select-box';
import './SelectBox.scss';
import { Button } from 'devextreme-react/button';
import Box, { Item } from 'devextreme-react/box';
import { TextBox } from 'devextreme-react/text-box';

interface ISharedProps {
  displayExpr: string;
}

interface IRelatedInputProps extends ISharedProps {
  placeholder: (selectedAction: any) => string;
  type: (selectedAction: any) => 'selectBox' | 'text' | '';
  dataSource: (selectedAction: any) => any;
}

interface ISelectBoxProps extends ISharedProps {
  dataSource: any[];
  label?: string;
  minSearchLength?: number;
  searchEnabled?: boolean;
  searchExpr?: string;
  className?: string;
  actionName?: string;
  handleAction: (data: any, relatedData: any) => void;
  loadOptions?: () => any;
  relatedInput?: IRelatedInputProps;
  disabled?: boolean;
  placeholder?: string;
}

function SelectBox({
  dataSource,
  label,
  placeholder,
  minSearchLength = 0,
  displayExpr,
  searchEnabled,
  searchExpr,
  className,
  actionName = 'Add',
  handleAction,
  loadOptions,
  disabled = false,
  relatedInput,
}: ISelectBoxProps) {
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedRelatedValue, setSelectedRelatedValue] = useState(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [relatedValueDataSource, setRelatedValueDataSource] = useState(null);
  const relatedInputType = relatedInput?.type(selectedValue);
  const relatedInputPlaceholder = relatedInput?.placeholder(selectedValue);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const handleValueChanged = (e: any) => {
    setSelectedValue(e.value);
  };

  const isButtonEnabled = selectedValue !== null || inputValue.length > 0;

  const handleRelatedValueChanged = (e: any) => {
    setSelectedRelatedValue(e.value);
  };

  const handleValueChange = (e: any) => {
    setInputValue(e.value);
  };

  useEffect(() => {
    loadOptions?.();
  }, []);

  useEffect(() => {
    setSelectedRelatedValue(null);
    setInputValue('');
    if (showRelatedInput && relatedInputType === 'selectBox') {
      relatedInput.dataSource(selectedValue).then((response: any) => {
        setRelatedValueDataSource(response);
      });
    }
  }, [selectedValue]);

  useEffect(() => {
    if (!disabled) return;
    setSelectedValue(null);
  }, [disabled]);

  const showRelatedInput =
    relatedInput !== undefined &&
    selectedValue !== null &&
    relatedInputType !== '';

  const getRelatedInputComponent = () => {
    return relatedInputType === 'selectBox' ? (
      <SelectBoxComponent
        dataSource={relatedValueDataSource}
        displayExpr={relatedInput!.displayExpr}
        searchEnabled={searchEnabled}
        placeholder={relatedInput!.placeholder(selectedValue)}
        labelMode="static"
        searchMode="contains"
        searchExpr={searchExpr}
        minSearchLength={minSearchLength}
        onValueChanged={handleRelatedValueChanged}
      />
    ) : (
      <TextBox
        value={inputValue}
        onValueChanged={handleValueChange}
        placeholder={relatedInputPlaceholder}
        valueChangeEvent="keyup"
      />
    );
  };

  return (
    <div className={`${className} select-box`}>
      <div className="fieldset-label">{label}</div>
      <Box direction="row">
        <Item ratio={1}>
          <SelectBoxComponent
            dataSource={dataSource}
            displayExpr={displayExpr}
            searchEnabled={searchEnabled}
            placeholder={placeholder}
            labelMode="static"
            searchMode="contains"
            searchExpr={searchExpr}
            minSearchLength={minSearchLength}
            onValueChanged={handleValueChanged}
            disabled={disabled}
            value={selectedValue}
          />
        </Item>
        {showRelatedInput && (
          <Item ratio={1}>
            <div className="padding-left">{getRelatedInputComponent()}</div>
          </Item>
        )}
        <Item ratio={1}>
          <Button
            text={actionName}
            type="default"
            stylingMode="text"
            height={'46px'}
            width={'50px'}
            onClick={() => {
              handleAction(selectedValue, selectedRelatedValue ?? inputValue);
              setInputValue('');
            }}
            disabled={
              disabled ||
              selectedValue === null ||
              (showRelatedInput && !selectedRelatedValue && !inputValue.length)
            }
          />
        </Item>
      </Box>
    </div>
  );
}

export default SelectBox;
