import React, { useCallback, useState } from "react";
import { AlertColor, Backdrop, CircularProgress, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RiskCaseApi from "../../../api/riskCase/riskCaseApi";
import {
  RiskCaseViewModelDto,
} from "../../../api/types/types";
import RiskCaseForm from "../ui/forms/RiskCaseForm";
import CustomDialog from "../ui/custom-dialog/CustomDialog";
import { RiskCaseViewDropdownsData } from "../models/RiskCaseViewDropdownsData";
import { FormStatus } from "../models/FormStatus";
import CustomSnackbar from "../../custom-snackbar";
import { useDispatch } from "react-redux";
import { notifyRiskCaseChange, setMenuRiskCase } from "../../../slices/riskCasesSlice";
import { setRiskCase } from "../../../slices/discoverySlice";

const CreateRiskCaseContainer = () => {
  const [data, setData] = useState<RiskCaseViewModelDto | null>(null);
  const [dropdownData, setDropdownData] =
    useState<RiskCaseViewDropdownsData | null>(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>('success');
  const [formData, setFormData] = useState<{data: RiskCaseViewModelDto, status: FormStatus} | null>(null)
  const dispatch = useDispatch();

  const fetchTemplateData = async (): Promise<RiskCaseViewModelDto> => {
    return await RiskCaseApi.getRiskCaseTemplate([]);
  };
  const fetchDataDropdown = async (): Promise<RiskCaseViewDropdownsData> => {
    try {
      const ownersPromise = RiskCaseApi.getOwnersDropdown();
      const subscribersPromise = RiskCaseApi.getSubscriberDropdown();
      const suppliersPromise = RiskCaseApi.getSuppliersDropdown();
      const keywordModelsPromise = RiskCaseApi.getKeywordModelsDropdown();

      const arrResult = await Promise.allSettled([
        ownersPromise,
        subscribersPromise,
        suppliersPromise,
        keywordModelsPromise,
      ]);

      const dropdownData: RiskCaseViewDropdownsData = {
        owners: arrResult[0].status === "fulfilled" ? arrResult[0].value : [],
        subscribers:
          arrResult[1].status === "fulfilled" ? arrResult[1].value : [],
        suppliers:
          arrResult[2].status === "fulfilled" ? arrResult[2].value : [],
        keywordModels:
          arrResult[3].status === "fulfilled" ? arrResult[3].value : [],
      };

      // Log errors for any promises that were rejected
      arrResult.forEach((result, index) => {
        if (result.status === "rejected") {
          console.error(
            `Error fetching ${
              ["owners", "subscribers", "suppliers", "keyword models"][index]
            }:`,
            result.reason
          );
        }
      });

      return dropdownData;
    } catch (error) {
      throw error;
    }
  };

  const handleOpenDialogClick = useCallback(async () => {
    setPopupVisible(true);
    setIsLoading(true);

    try {
      const [templateData, dropdownData] = await Promise.all([
        fetchTemplateData(),
        fetchDataDropdown(),
      ]);

      setData(templateData);
      setDropdownData(dropdownData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleFormUpdate = useCallback((data: RiskCaseViewModelDto, status: FormStatus) => {
    setFormData({ data, status });
  }, []);

  const hidePopup = useCallback(() => {
    setPopupVisible(false);
  }, []);

  const handleSaveClick = useCallback(async () => {
    if (!formData) {
      return;
    }

    if (formData.status.errorMessages.length > 0) {
      const firstErrorMessage = formData.status.errorMessages[0];
      displaySnackbar(firstErrorMessage, "error");
      return;
    }

    try {
      setIsSaving(true)
      const data = await RiskCaseApi.createRiskCase(formData.data);
      dispatch(
        setRiskCase({
          data: structuredClone(formData.data),
          isPopupVisible: false,
        })
      );
      dispatch(notifyRiskCaseChange());      

      hidePopup();
      displaySnackbar('Changes saved successfully', "success");

      const menuData = await RiskCaseApi.getRiskCasesMenu();
      dispatch(setMenuRiskCase(menuData));
    } catch (error) {
      console.error(error)
      displaySnackbar('Failed to save changes', "error");
    } finally {
      setIsSaving(false)
    }

  }, [formData]);

  const displaySnackbar = useCallback((message: string, severity: "error" | "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  }, []);

  return (
    <>
      <div className="add-button">
        <Tooltip title="Add a row">
          <IconButton onClick={handleOpenDialogClick}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>

      {isPopupVisible && !isSaving && (
        <CustomDialog
          title="Risk Case"
          isLoading={isLoading}
          onCloseClick={hidePopup}
          onSaveClick={handleSaveClick}
          onCancelClick={hidePopup}
        >
          <RiskCaseForm data={data} dropdownData={dropdownData} onFormUpdate={handleFormUpdate} />
        </CustomDialog>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSaving}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default CreateRiskCaseContainer;