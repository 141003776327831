// @ts-ignore: Implicit any module
import SuppliersAPI from "../../../../api/suppliers/suppliersAPI";

import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DataGrid from "../../../../components/data-grid/DataGrid";
import "./SupplierSettings.scss";
import { GridColDef } from "@mui/x-data-grid";

const SupplierSettings = () => {
  const [supplierWeights, setSupplierWeights] = useState<any>();

  const columns: GridColDef[] = [
    {
      field: "weight",
      headerName: "Weight",
      editable: false,
    },
    {
      field: "label",
      headerName: "Label",
    },
  ];

  const loadData = async () => {
    const response = await SuppliersAPI.getAllSupplierWeights();
    setSupplierWeights(response);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="supplier-settings">
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Supplier Weights</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataGrid
            data={supplierWeights}
            columns={columns}
            loadData={loadData}
            updateData={async (id: number, data: any) => {
              await SuppliersAPI.updateSupplierWeight(data);
            }}
            isDeletable={false}
            isCreatable={false}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SupplierSettings;
