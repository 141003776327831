import React from "react";

import {
	Autocomplete,
	Chip,
	FormControl,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	SelectProps,
	SxProps,
	TextField,
	Theme,
} from "@mui/material";
import {
	labelStyles,
	inputProps_textField,
	chipContainerStyles,
	selectMenuProps,
} from "../styles";
import "./AltruityTagBox.scss";
import { TagBoxOption } from "../../form/index";

interface TagBoxProps {
	name: string;
	label?: string | null;
	defaultValues?: any[] | null;
	values: any[];
	autoComplete?: boolean | null;
	options?: TagBoxOption[] | null;
	selectProps?: SelectProps | null;
	formControlSx?: SxProps<Theme> | undefined;
	placeholder?: string | null;
	onChange: (field: string, e: any) => void;
	onDeleteOption: (field: string, e: any) => void;
}

export default function AltruityTagBox({
	label,
	defaultValues,
	values,
	onChange,
	onDeleteOption,
	name,
	options,
	selectProps,
	autoComplete,
	formControlSx,
	placeholder,
}: TagBoxProps) {
	console.log(`loading ${name}`);
	console.log(values);
	return (
		<FormControl fullWidth sx={formControlSx}>
			{label ? (
				<InputLabel shrink sx={labelStyles}>
					{label}
				</InputLabel>
			) : null}
			{autoComplete ? (
				<Autocomplete
					multiple
					freeSolo
					options={values}
					value={values}
					defaultValue={defaultValues ?? undefined}
					onChange={(e: any) => {
						onChange(name, e);
					}}
					sx={{ ...inputProps_textField.normal.style }}
					renderInput={(params) => (
						<TextField
							{...params}
							name={name}
							variant="filled"
							placeholder="Enter a value"
							className="tagbox-text-field"
						/>
					)}
					renderTags={() => (
						<div style={chipContainerStyles}>
							{values.map((value) => (
								<Chip
									key={value}
									label={value}
									onDelete={(e: any) => {
										onDeleteOption(name, value);
									}}
									onMouseDown={(event: any) =>
										event.stopPropagation()
									}
								/>
							))}
						</div>
					)}
				/>
			) : (
				<Select
					{...selectProps}
					multiple
					value={values}
					defaultValue={defaultValues ?? undefined}
					onChange={(e: any) => {
						onChange(name, e);
					}}
					input={<OutlinedInput />}
					sx={{ ...inputProps_textField.normal.style, width: "100%" }}
					displayEmpty
					MenuProps={selectMenuProps}
					renderValue={(selected) => {
						if (placeholder && selected.length === 0) {
							return <em>{placeholder}</em>;
						}
						return (
							<div style={chipContainerStyles}>
								{values.map((value) => (
									<Chip
										key={value}
										label={
											options
												? options.find(
														(c: TagBoxOption) =>
															c.value === value
												  )?.text ?? `Unknown ${value}`
												: ""
										}
										onDelete={(e: any) =>
											onDeleteOption(name, value)
										}
										onMouseDown={(event: any) =>
											event.stopPropagation()
										}
									/>
								))}
							</div>
						);
					}}
				>
					{" "}
					{placeholder ? (
						<MenuItem disabled value="">
							<em>{placeholder}</em>
						</MenuItem>
					) : null}
					{options
						? options.map((code: TagBoxOption) => (
								<MenuItem key={code.value} value={code.value}>
									{code.text}
								</MenuItem>
						  ))
						: null}
				</Select>
			)}
		</FormControl>
	);
}
