import React from "react";
import { TextField, FormControl, InputLabel, Box } from "@mui/material";
import { labelStyles, inputProps_textField } from "../styles";
import HelpIcon from "../help/HelpIcon.jsx";

export default function AltruityTextField({
	label,
	width,
	onChange,
	helpConent,
	inputRef,
	...props
}) {
	return (
		<FormControl className="altruity-field" style={{ width: width }}>
			{helpConent ? (
				<Box display="flex">
					<InputLabel shrink sx={labelStyles}>
						{label}
					</InputLabel>
					<HelpIcon label={label}>{helpConent}</HelpIcon>
				</Box>
			) : (
				<InputLabel shrink sx={labelStyles}>
					{label}
				</InputLabel>
			)}
			<TextField
				{...props}
				onChange={onChange}
				InputProps={
					inputRef?.current?.disabled ?? false
						? inputProps_textField.disabled
						: inputProps_textField.normal
				}
				inputRef={inputRef}
			/>
		</FormControl>
	);
}
