import {
	Box,
	FormControlLabel,
	InputLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import React from "react";
import { HelpIcon, RadioOption } from "../../../components/form/index";
import { labelStyles } from "../styles";

interface RadioGroupProps {
	help?: string | null;
	label?: string | null;
	name: string;
	options: RadioOption[];
	row?: boolean | null;
	value: any;
	defaultValue?: any | null;
	onChange: (field: string, e: any) => void;
}

export default function AltruityRadioGroup({
	name,
	help,
	label,
	value,
	defaultValue,
	row,
	options,
	onChange,
}: RadioGroupProps) {
	return (
		<>
			{help && label ? (
				<Box display="flex" className="typography-container">
					<Typography>{label}</Typography>
					<HelpIcon label={label}>{help}</HelpIcon>
				</Box>
			) : label ? (
				<InputLabel shrink sx={labelStyles}>
					{label}
				</InputLabel>
			) : null}

			<RadioGroup
				row={row ?? undefined}
				name={name}
				value={value}
				defaultValue={defaultValue}
				onChange={(e: any) => onChange(name, e)}
			>
				{options.map((opt) => (
					<FormControlLabel
						key={opt.value}
						value={opt.value}
						control={<Radio />}
						label={opt.text}
					/>
				))}
			</RadioGroup>
		</>
	);
}
