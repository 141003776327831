import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CustomMenuItem.scss';


const CustomMenuItem = ({
  selectedMenuOption,
  setSelectedMenuOption,
  option,
  className = 'root-menu-item',
}) => {
  const navigate = useNavigate();

  const onMenuOptionClick = () => {
    setSelectedMenuOption(option.text);
    if (option.href) {
      navigate(option.href);
    };
  };

  const getClassName = (className) => {
    return `${className} ${
      selectedMenuOption === option.id || option.text === selectedMenuOption ? 'selected' : ''
    }`;
  };

  return (
    <div className={getClassName(className)} onClick={onMenuOptionClick}>
      <p className={`${className}-text`}>
        <span>{option.text}</span>
      </p>
    </div>
  );
};

export default CustomMenuItem;
