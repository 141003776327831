// @ts-ignore: Implicit any module
import httpClient from "../configs/axiosConfig";
// @ts-ignore: Implicit any module
import config from "../../config";
import { IRiskCase } from "../../components/risk-discovery/types/RiskCase";
import { boolean, number, string } from "yargs";

const { ALTRUITY_API_URL } = config;

export interface RiskCaseCollection {
  generalInformation: {
    riskCaseId: number;
    dateAdded: string;
    dateUpdated: string;
    dateDeleted: string | null;
    riskCaseName: string;
    isActive: boolean;
    isDeleted: boolean;
    comments: any; 
    description: any; 
    owner: {
      
    } | null;
    subscribers: any; 
    suppliers: any; 
    keywordModels: any; 
  };
  measures: any; 
  caseLogs?: {
    caseLogs: 
     Array<{ riskCaseLogEntryId: number; 
      date: string;
      authorFullName: string; 
      entryText: string;
      files: {
        riskCaseLogEntryId: number;
        filename: string;
        operationType: string;
        base64Data: string;
  
      };
    }>;
    
  };
  articles?: {
    articles: Array<{
      id: number;
      articleId: number;
      title: string;
      addDate: string;
      articleCategories: [];
      articleCompanies: [];
      articleEntities: [];
      articleKeywords: [];
      articleLinks: [];
      articleRiskCases: []
      authorByLine: [];
      content: string;
      dateAdded:string;
          dateDeleted: string;
      dateUpdated:  string;
      description:  string;
      imageUrl:  string;
      isActive:  boolean;
      isDeleted: boolean;
      language:  string;
      medium:  string;
      negativeSentiment: number;
      neutralSentiment: number;
      positiveSentiment: number;
      pubDate:  string;
      refreshDate:  string;
      score: number;
      semanticSummary:  string;
      sourceDomain:  string;
      sourceLocation:  string;
      supplierArticles: [];
      url: string;
    }>;
  }
}



const RiskDiscovery = {
  getRiskDiscoveryMenu: async function () {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskDiscovery/GetDiscoveryMenuItems`
    );
    return data.result;
  },

  getSelectBoxOptions: async function (
    actionName: string,
    kwmOrSupplierId: number,
    discoveryTypeName: string
  ) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskDiscovery/GetSelectBoxOptions?actionName=${actionName}&kwmOrSupplierId=${kwmOrSupplierId}&discoveryTypeName=${discoveryTypeName}`
    );
    return data.result;
  },

  archiveArticles: async function (articleIds: number[]) {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/RiskDiscovery/ArchiveArticles`,
      articleIds
    );
    return data.result;
  },

  archiveCollections: async function (articleIds: number[]) {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/RiskDiscovery/ArchiveCollections`,
      articleIds
    );
    return data.result;
  },

  createCollection: async function (
    collectionName: string,
    kwmOrSupplierId: number | undefined,
    articleIds: number[],
    discoveryTypeName: string
  ) {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/RiskDiscovery/CreateCollection`,
      { kwmOrSupplierId, collectionName, articleIds, discoveryTypeName }
    );
    return data.result;
  },

  addArticlesToCollection: async function (
    collectionId: number,
    articleIds: number[]
  ) {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/RiskDiscovery/AddArticlesToCollection`,
      { collectionId, articleIds }
    );
    return data.result;
  },

  createArticleRiskCase: async function (
    riskCaseName: string,
    articleIds: number[],   
    keywordModelId: number | null,
  ) {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/RiskDiscovery/CreateArticleRiskCase`,
      { riskCaseName, keywordModelId, articleIds }
    );
    return data.result;
  },

  createCollectionRiskCase: async function (
    collectionIds: number[],
    body: RiskCaseCollection ,
  ) {
    const params = new URLSearchParams();
    collectionIds.forEach(id => params.append('collectionIds', id.toString()));
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/RiskDiscovery/CreateCollectionRiskCase?${params.toString()}`,
       body 
    );
    return data.result;
  },  

  addArticlesToRiskCase: async function (
    riskCaseId: number,
    articleIds: number[]
  ) {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/RiskDiscovery/AddArticlesToRiskCase`,
      { riskCaseId, articleIds }
    );
    return data.result;
  },

  addCollectionsToRiskCase: async function (
    riskCaseId: number,
    collectionIds: number[]
  ) {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/RiskDiscovery/AddCollectionsToRiskCase`,
      { riskCaseId, collectionIds }
    );
    return data.result;
  },
};

export default RiskDiscovery;
