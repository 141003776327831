// @ts-ignore: Implicit any module
import httpClient from "../configs/axiosConfig";
// @ts-ignore: Implicit any module
import config from "../../config";
import { ResponseDto } from "../types/types";
import {
	NsnBatchDto,
	NsnBatchRequestDto,
	NsnSolicitationsResultDto,
} from "../types/types";
const { NSN_SERVICE_URL } = config;

const Batch = {
	/**
	 * Gets information for a particular cage code.
	 * @param cage
	 * @returns
	 */
	getBatches: async function (): Promise<ResponseDto<NsnBatchDto[]>> {
		const url = `${NSN_SERVICE_URL}/Batch`;
		const { data } = await httpClient.get(url);
		return data as ResponseDto<NsnBatchDto[]>;
	},

	/**
	 * Gets information for a particular cage code.
	 * @param cage
	 * @returns
	 */
	getBatch: async function (
		batchId: number
	): Promise<ResponseDto<NsnBatchDto>> {
		const url = `${NSN_SERVICE_URL}/Batch/${batchId}`;
		const { data } = await httpClient.get(url);
		return data as ResponseDto<NsnBatchDto>;
	},

	/**
	 * Gets information for a particular cage code.
	 * @param cage
	 * @returns
	 */
	getBatchFile: async function (
		batchId: number
	): Promise<ResponseDto<string>> {
		const url = `${NSN_SERVICE_URL}/Batch/File/${batchId}`;
		const { data } = await httpClient.get(url);
		return data as ResponseDto<string>;
	},

	/**
	 * Gets information for a particular cage code.
	 * @param cage
	 * @returns
	 */
	getBatchResults: async function (
		batchId: number
	): Promise<ResponseDto<NsnSolicitationsResultDto>> {
		const url = `${NSN_SERVICE_URL}/Batch/Results/${batchId}`;
		const { data } = await httpClient.get(url);
		return data as ResponseDto<NsnSolicitationsResultDto>;
	},

	/**
	 * Begins a new batch request for NSN Query
	 * @param {NsnBatchRequestDto} request Batch request information
	 * @returns ID of the batch request
	 */
	postBatch: async function (
		request: NsnBatchRequestDto
	): Promise<ResponseDto<number>> {
		const formData = new FormData();

		if (request.file) {
			formData.append("file", request.file);
		}

		formData.append("description", request.description ?? "");
		formData.append("includeAwarded", `${request.includeAwarded}`);
		formData.append("includeSosOnly", `${request.includeSosOnly}`);
		formData.append(
			"dibbsHotOpportunities",
			`${request.dibbsHotOpportunities}`
		);
		formData.append("dibbsLookupDays", `${request.dibbsLookupDays}`);

		// Costruct items list for form data.
		for (var i = 0; i < request.items.length; i++) {
			const item = request.items[i];
			formData.append(`items[${i}]`, item);
		}

		//formData.append("items", `${request.items.join("\n")}`);
		formData.append("useNiin", `${request.useNiin}`);
		formData.append("useCage", `${request.useCage}`);
		formData.append("usePartNumber", `${request.usePartNumber}`);
		formData.append("useFsc", `${request.useFsc}`);
		formData.append("useNomenclature", `${request.useNomenclature}`);
		formData.append("useCharacteristics", `${request.useCharacteristics}`);
		formData.append("moe", `${request.moe}`);
		formData.append("amc", `${request.amc}`);
		formData.append("amsc", `${request.amsc}`);
		formData.append("rncc", `${request.rncc}`);
		formData.append("rnvc", `${request.rnvc}`);
		formData.append("isOpen", `${request.isOpen}`);
		formData.append("isClosed", `${request.isClosed}`);
		formData.append("isAwarded", `${request.isAwarded}`);
		formData.append("notInSam", `${request.notInSam}`);

		if (request.excludedCages) {
			for (var j = 0; j < request.excludedCages.length; j++) {
				const item = request.excludedCages[j];
				formData.append(`excludedCages[${j}]`, item);
			}
		}

		formData.append(
			"useApproximateSearch",
			`${request.useApproximateSearch}`
		);

		const { data } = await httpClient.post(
			`${NSN_SERVICE_URL}/Batch`,
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);
		return data as ResponseDto<number>;
	},
};

export default Batch;
