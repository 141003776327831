import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Switch,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PermissionsAndUsers from "./PermissionsAndUsers";
import {
  AppResourceDto,
  RoleDto,
  RoleResourceDto,
} from "../../../../api/types/types";

const RoleRow: React.FC<{
  role: RoleDto;
  appResources: AppResourceDto[];
  roleResources: RoleResourceDto[];
  setRoleResources: React.Dispatch<React.SetStateAction<RoleResourceDto[]>>;
  isAdding: boolean;
  onUpdateRole: (updatedRole: RoleDto) => void;
}> = ({
  role,
  appResources,
  roleResources,
  setRoleResources,
  isAdding,
  onUpdateRole,
}) => {
  const [open, setOpen] = useState(false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpdateRole({
      ...role,
      isActive: event.target.checked,
    });
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{role.roleName}</TableCell>
        <TableCell>
          <Switch checked={role.isActive} onChange={handleSwitchChange} />
        </TableCell>
        {isAdding && <TableCell />}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <PermissionsAndUsers
              role={role}
              appResources={appResources}
              roleResources={roleResources}
              setRoleResources={setRoleResources}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RoleRow;
