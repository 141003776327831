import React from "react";
import { labelStyles, inputProps_textField } from "../styles";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { SelectFieldOption } from "../../form/index";

interface SelectFieldProps {
	name: string;
	label?: string | null;
	defaultValue?: any;
	value: any;
	options: SelectFieldOption[];
	placeholder?: string | null;
	onChange: (field: string, e: any) => void;
}

export default function AltruitySelectField({
	label,
	name,
	defaultValue,
	value,
	options,
	onChange,
	placeholder,
}: SelectFieldProps) {
	return (
		<FormControl fullWidth>
			{label ? (
				<InputLabel shrink sx={labelStyles}>
					{label}
				</InputLabel>
			) : null}
			<Select
				displayEmpty
				name="supplierRelationTypeId"
				value={value}
				defaultValue={defaultValue}
				onChange={(e) => {
					onChange(name, e);
				}}
				style={inputProps_textField.normal.style}
				renderValue={(selected) => {
					if (placeholder && selected.length === 0) {
						return <em>{placeholder}</em>;
					}

					return options.find((c) => c.value === selected)?.text;
				}}
			>
				{placeholder ? (
					<MenuItem disabled value="">
						<em>{placeholder}</em>
					</MenuItem>
				) : null}

				{options.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.text}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
