import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import {
	DataGrid,
	GridRowSelectionModel,
	GridCallbackDetails,
} from "@mui/x-data-grid";
import { Box, SnackbarCloseReason } from "@mui/material";
import {
	ResponseDto,
	NsnSearchMultipleDto,
	NsnSearchResultDto,
} from "../../../api/types/types";
import { NsnSearchResponseDto } from "../../../api/types/types";
import { createColumn } from "../../../utils/data-grid";
import { b64toBlob } from "../../../utils/files";
import NsnApi from "../../../api/nsn/nsnAPI";
import fileDownload from "js-file-download";
import FileDownloadToolbar from "../../../components/data-grid/FileDownloadToolbar";
import {
	ValidationSnackbar,
	FileDownloadSnackbar,
} from "../../../components/form/index";

const initalData: NsnSearchResultDto[] = [];

// Create column definitions
const columns = [
	{ ...createColumn("cage", "CAGE"), width: 70 },
	{ ...createColumn("cageName", "CAGE Name"), flex: 1 },
	{ ...createColumn("part", "Part Number"), minWidth: 150 },
	{ ...createColumn("nsn", "NSN"), width: 150 },
	{ ...createColumn("itemName", "Item Name"), flex: 1 },
	{ ...createColumn("rncc", "RNCC"), width: 80 },
	{ ...createColumn("rnvc", "RNVC"), width: 80 },
	{ ...createColumn("rnsc", "RNSC"), width: 80 },
	{ ...createColumn("amscG", "AMSC-G"), width: 80 },
	{
		...createColumn("dtNiinAsgmt", "DT NIIN ASGMT"),
		width: 130,
		flex: 0,
	},
];

export default function NsnSearchResultsPage() {
	// Get data from Location
	const location = useLocation();
	const locState = location.state;
	const query = locState?.query as NsnSearchMultipleDto;

	const navigate = useNavigate();

	// Config States
	const [snackBarState, setSnackBarState] = useState({
		open: false,
		message: "",
	});

	const [pageState, setPageState] = useState({
		isLoading: false,
		data: initalData,
		total: 0,
		page: 1,
		pageSize: 50,
	});

	const [fileSnackBarState, setFileSnackBarState] = useState({
		isLoading: false,
		type: "csv",
	});

	function CustomToolbar() {
		return <FileDownloadToolbar onExportClick={handleFileDownload} />;
	}

	/**
	 * Downloads a file to the user's computer
	 * @param id BatchId
	 * @param path path to the file on the server.
	 */
	async function handleFileDownload(type: string) {
		try {
			// Show 'File downloading' snackbar
			setFileSnackBarState((prevState) => ({
				...prevState,
				isLoading: true,
				type: type,
			}));

			// Get all rows.
			const response = await NsnApi.getSearchFile(type, query);

			const mime: string =
				type === "csv"
					? "text/csv"
					: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

			if (response.isSuccess) {
				// Download file to user.
				const blob = b64toBlob(response.result[1], mime);
				setFileSnackBarState((prevState) => ({
					...prevState,
					isLoading: false,
				}));
				fileDownload(blob, response.result[0]);
			} else {
				setFileSnackBarState((prevState) => ({
					...prevState,
					isLoading: false,
				}));

				setSnackBarState({
					open: true,
					message: response.message,
				});
			}
		} catch (e) {
			setFileSnackBarState((prevState) => ({
				...prevState,
				isLoading: false,
			}));
			setSnackBarState({
				open: true,
				message: "Error downloading file.",
			});
			console.error(e);
		}
	}

	/**
	 * Load data on page state change
	 */
	useEffect(() => {
		/**
		 * Displays error message
		 * @param message
		 * @param error
		 */
		const showError = (message: string, error?: any) => {
			setPageState((oldState) => ({
				...oldState,
				isLoading: false,
			}));

			if (error) {
				console.error(message, error);
			}
			setSnackBarState({
				open: true,
				message: message,
			});
		};

		/**
		 * / Fetch new when new page or new page size.
		 */
		const fetchData = async () => {
			setPageState((oldState) => ({ ...oldState, isLoading: true }));
			const newQuery = {
				...query,
				page: pageState.page,
				rowsPerPage: pageState.pageSize,
			};

			try {
				// Get paginated data from the server.
				const response: ResponseDto<NsnSearchResponseDto> =
					await NsnApi.searchFull(newQuery);

				if (response.isSuccess === true) {
					setPageState((oldState) => ({
						...oldState,
						isLoading: false,
						data: response.result?.results,
						total: response.result?.totalRows,
					}));
				} else {
					showError(response.message);
				}
			} catch (error) {
				showError("Error sending query!", error);
			}
		};

		fetchData();
	}, [pageState.page, pageState.pageSize, query]);

	/**
	 * Closes the validation SnackBar
	 * @param {*} event
	 * @param {*} reason
	 * @returns
	 */
	const handleSnackBarClose = (
		event: React.SyntheticEvent | Event,
		reason?: SnackbarCloseReason
	) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackBarState({ open: false, message: "" });
	};

	return (
		<>
			<Box className="page-container">
				<h4 className="page-title">Search Results</h4>
				<DataGrid
					autoHeight
					density="compact"
					getRowId={(row) => row.id * Math.random()}
					rows={pageState.data}
					rowCount={pageState.total}
					loading={pageState.isLoading}
					pageSizeOptions={[10, 50, 100]}
					paginationModel={{
						page: pageState.page - 1,
						pageSize: pageState.pageSize,
					}}
					paginationMode="server"
					onPaginationModelChange={({ page, pageSize }) =>
						setPageState((oldState) => ({
							...oldState,
							page: page + 1,
							pageSize: pageSize,
						}))
					}
					onRowSelectionModelChange={async (
						rowSelectionModel: GridRowSelectionModel,
						details: GridCallbackDetails
					) => {
						const row = details.api.getRow(rowSelectionModel[0]);

						navigate("/nsn/details", {
							state: {
								nsn: row.nsn,
							},
						});
					}}
					columns={columns}
					slots={{ toolbar: CustomToolbar }}
				/>
			</Box>
			<ValidationSnackbar
				open={snackBarState.open}
				onClose={handleSnackBarClose}
				message={snackBarState.message}
				severity="error"
			/>
			<FileDownloadSnackbar
				isLoading={fileSnackBarState.isLoading}
				type={fileSnackBarState.type}
			/>
		</>
	);
}
