import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  GridRowModesModel,
  GridRowModes,
  DataGrid as MUIDataGrid,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
  GridValidRowModel,
} from "@mui/x-data-grid";
import LinkIcon from "@mui/icons-material/Link";
import "./DataGrid.scss";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import SupplierPopup from "./SupplierPopup";
import EditToolbar from "./EditToolbar";
import { IconButton, Link } from "@mui/material";

interface IPopupFields {
  key: string;
  label: string;
}

interface DataGridProps {
  data: any[];
  columns: (GridColDef<GridValidRowModel> & { editable?: boolean })[];
  createData?: (data: any) => void;
  loadData?: () => void;
  updateData?: (id: number, data: any, createdSupplierId?: number) => void;
  deleteData?: (id: number) => void;
  getLink?: (id: number) => string;
  linkKeyExpr?: string;
  popupFields?: IPopupFields[];
  popupTitle?: string;
  popupCreateLabel?: string;
  handleCreate?: (data: any) => void;
  isEditable?: boolean;
  isDeletable?: boolean;
  isCreatable?: boolean;
  loading?: boolean;
  handleRowClick?: (data: any) => void;
}

const DataGrid = ({
  columns,
  data,
  loadData,
  createData,
  updateData,
  deleteData,
  linkKeyExpr,
  getLink,
  popupFields,
  handleCreate,
  isEditable = true,
  isDeletable = true,
  isCreatable = true,
  handleRowClick,
}: DataGridProps) => {
  const [rows, setRows] = useState<any[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [deleteRowId, setDeleteRowId] = useState<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [newEntity, setNewEntity] = useState<any>({});

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCreateClick = (id: GridRowId) => () => {
    const row = rows.find((r) => r.id === id);
    setNewEntity(row);
    setCreateModalVisible(true);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeleteRowId(id);
    setOpenDeleteDialog(true);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const closeDialog = () => {
    setDeleteRowId(null);
    setOpenDeleteDialog(false);
  };

  const confirmDelete = async () => {
    if (deleteRowId != null && deleteData) {
      await deleteData(parseInt(deleteRowId.toString()));
      setRows(rows.filter((row) => row.id !== deleteRowId));
      closeDialog();
    }
  };

  const cancelDelete = () => {
    closeDialog();
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow: any = { ...newRow, isNew: false };

    if (newRow.isNew && createData) {
      await createData(updatedRow);
    } else if (!newRow.isNew && updateData) {
      await updateData(updatedRow.id, updatedRow);
    }

    setRows((prevRows) =>
      prevRows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [newRow.id]: { mode: GridRowModes.View },
    }));

    loadData?.();

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    loadData!();
  }, []);

  useEffect(() => {
    setRows(data);
  }, [data]);

  const renderButton = (cellInfo: any) => {
    if (!linkKeyExpr || !cellInfo.row[linkKeyExpr]) return null;

    const link = getLink!(cellInfo.row[linkKeyExpr]);

    return (
      <Link
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
      >
        <IconButton size="small" color="primary">
          <LinkIcon fontSize="small" />
        </IconButton>
      </Link>
    );
  };

  const dataGridColumns: GridColDef[] = [
    ...columns.map((c) => {
      let column = c;
      column.flex = 1;
      column.align = "left";
      column.headerAlign = "left";
      column.editable = c.editable !== undefined ? c.editable : true;

      if (column.field === "link" && getLink) {
        column.renderCell = renderButton;
        column.editable = false;
      }

      return column;
    }),
    {
      field: "actions",
      type: "actions",
      cellClassName: "actions",
      align: "right",
      flex: 0.1,
      minWidth: 150 + (linkKeyExpr ? 100 : 0),
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const hasLink = linkKeyExpr && row[linkKeyExpr];

        if (isInEditMode) {
          return [
            <Button onClick={handleSaveClick(id)} color="inherit">
              Save
            </Button>,
            <Button onClick={handleCancelClick(id)} color="inherit">
              Cancel
            </Button>,
          ];
        }

        return [
          <>
            {linkKeyExpr && (
              <Button
                onClick={handleCreateClick(id)}
                color="inherit"
                disabled={hasLink}
              >
                Create
              </Button>
            )}
          </>,
          <>
            {isEditable && (
              <Button
                onClick={handleEditClick(id)}
                color="inherit"
                disabled={hasLink}
              >
                Edit
              </Button>
            )}
          </>,
          <>
            {isDeletable && (
              <Button onClick={handleDeleteClick(id)} color="inherit">
                Delete
              </Button>
            )}
          </>,
        ];
      },
    },
  ];

  const rowClickHandler: GridEventListener<"rowClick"> = (params) => {
    const rowData = params.row;
    handleRowClick?.(rowData);
  };

  return (
    <>
      <MUIDataGrid
        className="data-grid-base"
        rows={rows}
        columns={dataGridColumns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        onRowClick={rowClickHandler}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar as GridSlots["toolbar"],
        }}
        slotProps={{
          toolbar: {
            setRows,
            setRowModesModel,
            fieldToFocus: columns[0].field,
            isCreatable,
          },
        }}
      />

      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />

      <SupplierPopup
        visible={createModalVisible}
        onClose={() => setCreateModalVisible(false)}
        popupTitle="Create New Supplier Profile"
        popupFields={popupFields}
        popupCreateLabel="Create supplier"
        newEntity={newEntity}
        handleCreate={handleCreate!}
        loadData={loadData!}
      />
    </>
  );
};

export default DataGrid;
