import RolesTable from '../../components/User-Admin/Roles/RolesTable'
import React from 'react'

const Roles = () => {
  return (
    <div>
      <RolesTable/>
    </div>
  )
}

export default Roles
