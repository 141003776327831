import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { addSuppliers } from '../../../../slices/suppliersSlice';
import SuppliersAPI from '../../../../api/suppliers/suppliersAPI';
import DnbAPI from '../../../../api/dnb/dnbAPI';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Grid,
  Chip,
  OutlinedInput,
  InputAdornment,
  IconButton,
  AlertColor,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Popup } from 'devextreme-react';
import CustomSnackbar from '../../../../components/custom-snackbar';
import FormHelperText from '@mui/material/FormHelperText';
import NsnApi from '../../../../api/nsn/nsnAPI';

const GeneralInformation = () => {
  const [naicsOptions, setNaicsOptions] = useState({});
  const [relationshipTypes, setRelationshipTypes] = useState([]);
  const [supplierWeights, setSupplierWeights] = useState([]);
  const [formData, setFormData] = useState({
    supplierName: '',
    dateAdded: new Date().toISOString().split('T')[0],
    isActive: '',
    duns: '',
    ticker: '',
    edgarCik: '',
    supplierRelationTypeId: '',
    parentCompanyName: '',
    parentCompanyId: '',
    naics: [],
    cageCode: '',
    cageCodeHealth: null,
    supplierWeightId: null,
  });
  const [popupVisible, setPopupVisible] = useState(false);
  const [dunsValidationData, setDunsValidationData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState('success');

  const [validateForm, setValidateForm] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showOnlyActiveSuppliers = useSelector(
    (state) => state.suppliers.showOnlyActive
  );

  const handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDeleteChip = (chipToDelete) => {
    setFormData((prevData) => ({
      ...prevData,
      naics: prevData.naics.filter((naics) => naics !== chipToDelete),
    }));
  };

  const fetchNaicsOptions = async () => {
    try {
      const data = await SuppliersAPI.getAllNaicsCodes();
      setNaicsOptions(data);
    } catch (error) {
      console.error('Error fetching NAICS options:', error);
    }
  };

  const fetchRelationshipTypes = async () => {
    try {
      const data = await SuppliersAPI.getAllSupplierRelations();
      setRelationshipTypes(data);
    } catch (error) {
      console.error('Error fetching Relationship Types:', error);
    }
  };

  const fetchSupplierWeights = async () => {
    try {
      const data = await SuppliersAPI.getAllSupplierWeights();
      setSupplierWeights(data);
    } catch (error) {
      console.error('Error fetching Supplier Weights:', error);
    }
  };

  const fetchSuppliersData = async () => {
    try {
      const data = await SuppliersAPI.getSuppliersById(id);
      setFormData(data);
    } catch (error) {
      console.error('Error fetching Supplier Data:', error);
    }
  };

  const displayDunsValidationMessage = async () => {
    formData.duns.length > 0 ? setPopupVisible(true) : setPopupVisible(false);
    try {
      setDunsValidationData(null);
      const data = await DnbAPI.getCompanyNameByDuns(formData.duns);
      const validationData = {
        isError: false,
        companyName: data.companyName,
        duns: formData.duns,
      };

      setDunsValidationData(validationData);
    } catch (error) {
      console.error('Error validating D-U-N-S:', error);
      const validationData = {
        isError: false,
        companyName: null,
        duns: formData.duns,
      };
      setDunsValidationData(validationData);
    }
  };


  const validateCageCode = async () => {
    if (!formData.cageCode) {
      // If cageCode is undefined or empty, set health to null and show an alert
      setFormData((prevData) => ({ ...prevData, cageCodeHealth: null }));
      setSnackbarMessage('Please enter a CAGE Code.');
      setSnackbarOpen(true);
      setSnackbarSeverity('error');
      return;
    }
    if (formData.cageCode.length === 5) {
      try {
        const response = await NsnApi.getCageCode(formData.cageCode);
        // if (response.isActive) {
        if (response.exists === true) {
          setFormData((prevData) => ({
            ...prevData,
            cageCodeHealth: 'active',
          }));
          setSnackbarMessage('CAGE Code is valid.');
          setSnackbarOpen(true);
          setSnackbarSeverity('success');
        } else {
          setFormData((prevData) => ({
            ...prevData,
            cageCodeHealth: 'inactive',
          }));
          setSnackbarMessage('CAGE Code is invalid.');
          setSnackbarOpen(true);
          setSnackbarSeverity('error');
        }
      } catch (error) {
        console.error('Error al validar el Código CAGE:', error);
        setFormData((prevData) => ({ ...prevData, cageCodeHealth: 'error' }));
      }
    }
  };

  const handleCageCodeChange = (e) => {
    let value = e.target.value.toUpperCase();
    value = value.replace(/[^A-Z0-9]/g, '').slice(0, 5);
    setFormData((prevData) => ({ ...prevData, cageCode: value }));
  };

  const handleSave = async () => {
    setValidateForm(true);
    
    if(!formData.supplierWeightId) return;

    if (!formData.supplierName) {
      alert('Field "Name" is required.');
      return;
    }
    if (formData.naics.length === 0) {
      alert('Field "NAICS" is required.');
      return;
    }

    if (isNaN(formData.parentCompanyId)) {
      alert(
        'The "Parent Company Name" field must be empty or contain a numeric value.'
      );
      return;
    }

    try {
      const isEdit = id !== 'add-supplier';

      const { result: newSupplier } = isEdit
        ? await SuppliersAPI.updateSupplier(formData)
        : await SuppliersAPI.createSupplier(formData);

      setValidateForm(false);

      setFormData({
        supplierName: '',
        dateAdded: new Date().toISOString().split('T')[0],
        isActive: '',
        duns: '',
        ticker: '',
        edgarCik: '',
        supplierRelationTypeId: '',
        parentCompanyName: '',
        naics: [],
      });
      const data = await SuppliersAPI.getAllSuppliers(showOnlyActiveSuppliers);
      dispatch(addSuppliers({ allSuppliers: data }));
      alert('Data successfully saved.');
      navigate(`/scrm/suppliers/${newSupplier.id}`);
    } catch (error) {
      console.error('Error saving data:', error);
      alert('Failed to save data.');
    }
  };

  useEffect(() => {
    if (id !== 'add-supplier') {
      fetchSuppliersData();
    }
    fetchNaicsOptions();
    fetchRelationshipTypes();
    fetchSupplierWeights();
  }, [id]);

  const inputStyles = {
    backgroundColor: '#F5F5F5',
    borderRadius: '10px',
    height: '46px',
    borderColor: 'none',
    fontSize: '14px',
  };

  const labelStyles = {
    textTransform: 'uppercase',
    position: 'relative',
  };

  const chipContainerStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  };

  const menuProps = {
    PaperProps: {
      style: {
        width: '50px',
        height: '200px',
      },
    },
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className="new-supplier"
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              Name
            </InputLabel>
            <TextField
              required
              fullWidth
              id="supplierName"
              name="supplierName"
              value={formData.supplierName}
              onChange={handleFormFieldChange}
              InputProps={{ style: inputStyles }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              Date Added
            </InputLabel>
            <TextField
              fullWidth
              id="dateAdded"
              name="dateAdded"
              type="date"
              value={formData.dateAdded}
              onChange={handleFormFieldChange}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ style: inputStyles }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              Active?
            </InputLabel>
            <Select
              id="isActive"
              name="isActive"
              value={formData.isActive}
              onChange={handleFormFieldChange}
              displayEmpty
              style={inputStyles}
            >
              <MenuItem value="" sx={{ fontSize: '14px' }}>
                <em>Select...</em>
              </MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              D-U-N-S
            </InputLabel>
            <TextField
              fullWidth
              id="duns"
              name="duns"
              value={formData.duns}
              onChange={handleFormFieldChange}
              InputProps={{
                style: inputStyles,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={displayDunsValidationMessage}>
                      <CheckCircleIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              Ticker
            </InputLabel>
            <TextField
              fullWidth
              id="ticker"
              name="ticker"
              value={formData.ticker}
              onChange={handleFormFieldChange}
              InputLabelProps={{ style: labelStyles }}
              InputProps={{ style: inputStyles }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              Edgard Cik
            </InputLabel>
            <TextField
              fullWidth
              id="edgarCik"
              name="edgarCik"
              value={formData.edgarCik}
              onChange={handleFormFieldChange}
              InputLabelProps={{ style: labelStyles }}
              InputProps={{ style: inputStyles }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              Relationship
            </InputLabel>
            <Select
              id="supplierRelationTypeId"
              name="supplierRelationTypeId"
              value={formData.supplierRelationTypeId}
              onChange={handleFormFieldChange}
              displayEmpty
              style={inputStyles}
            >
              <MenuItem value="">
                <em>Select...</em>
              </MenuItem>
              {relationshipTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              Parent Company Name
            </InputLabel>
            <TextField
              fullWidth
              id="parentCompanyName"
              name="parentCompanyName"
              value={formData.parentCompanyName}
              onChange={handleFormFieldChange}
              InputProps={{
                style: inputStyles,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <CheckCircleIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              Parent Company ID
            </InputLabel>
            <TextField
              fullWidth
              id="parentCompanyId"
              name="parentCompanyId"
              value={formData.parentCompanyId}
              onChange={handleFormFieldChange}
              InputProps={{ style: inputStyles }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              CAGE CODE
            </InputLabel>
            <TextField
              fullWidth
              id="cageCode"
              name="cageCode"
              value={formData.cageCode}
              onChange={handleCageCodeChange}
              onBlur={validateCageCode}
              InputProps={{
                style: {
                  ...inputStyles,
                  backgroundColor:
                    formData.cageCodeHealth === 'active'
                      ? '#66BB6A'
                      : formData.cageCodeHealth === 'inactive'
                      ? '#ef9a9a'                      
                      : inputStyles.backgroundColor,
                  color:
                    formData.cageCodeHealth === 'active' ||
                    formData.cageCodeHealth === 'inactive'
                      ? '#000'
                      : inputStyles.color,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={validateCageCode}>
                      <CheckCircleIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth error={validateForm && !formData.supplierWeightId}>
            <InputLabel shrink sx={labelStyles}>
              Weight
            </InputLabel>
            <Select
              id="supplierWeightId"
              name="supplierWeightId"
              value={formData.supplierWeightId}
              onChange={handleFormFieldChange}
              displayEmpty
              style={inputStyles}
            >
              {supplierWeights.map((weight) => (
                <MenuItem key={weight.id} value={weight.id}>
                  {`${weight.weight} - ${weight.label}`}
                </MenuItem>
              ))}
            </Select>
            {validateForm && !formData.supplierWeightId && (
              <FormHelperText>Please select a weight.</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel shrink sx={labelStyles}>
              NAICS
            </InputLabel>
            <Select
              id="naics"
              name="naics"
              multiple
              value={formData.naics}
              onChange={handleFormFieldChange}
              displayEmpty
              input={<OutlinedInput />}
              sx={{ ...inputStyles, height: 'auto' }}
              MenuProps={menuProps}
              renderValue={() => (
                <div style={chipContainerStyles}>
                  {formData.naics.map((value) => (
                    <Chip
                      key={value}
                      label={`${value} - ${naicsOptions[value]}`}
                      onDelete={() => handleDeleteChip(value)}
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  ))}
                </div>
              )}
            >
              {Object.keys(naicsOptions).map((code) => (
                <MenuItem key={code} value={code}>
                  {`${code} - ${naicsOptions[code]}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ width: '100px' }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Popup
        visible={popupVisible}
        onHiding={() => setPopupVisible(false)}
        dragEnabled={true}
        hideOnOutsideClick={true}
        showCloseButton={true}
        title="D-U-N-S Validation Result"
        width={400}
        height={200}
      >
        <div>
          {dunsValidationData === null ? (
            <p style={{ margin: '20px' }}>Validating...</p>
          ) : dunsValidationData.isError ? (
            <p style={{ margin: '20px' }}>Failed to validate D-U-N-S.</p>
          ) : dunsValidationData.companyName === null ? (
            <p style={{ margin: '20px' }}>
              Unable to find <strong>{dunsValidationData.duns}</strong>.
            </p>
          ) : (
            <p style={{ margin: '20px' }}>
              DUNS number <strong>{dunsValidationData.duns}</strong> corresponds
              to <strong>{dunsValidationData.companyName}</strong>.
            </p>
          )}
        </div>
      </Popup>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
      
    </Box>
  );
};

export default GeneralInformation;
