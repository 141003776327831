import httpClient from "../configs/axiosConfig"
import config from "../../config"
import SupplierAlias from "./supplierAlias";
import SupplierLocation from "./supplierLocation";
import Supplier from "./supplier";
import SupplierRelation from "./supplierRelation";
import SupplierIndustry from "./supplierIndustry";
import SupplierRelationType from "./supplierRelationType";
import SupplierModels from "./supplierModels"
import SupplierWeight from "./supplierWeight";
/** @import { SupplierCreateDto } from '../types/types' */

const { SUPPLIERS_SERVICE_URL } = config;

const SuppliersAPI = {
    /**
     * @returns {Promise<NaicsCodeDto[]>}
     */
    getAllNaicsCodes: async function () {
        const { data } = await httpClient.get(`${SUPPLIERS_SERVICE_URL}/NaicsCode/GetAll`)
        const hashTable = data.result.reduce((acc, item) => {
            acc[item.code] = item.description;
            return acc;
        }, {});
        return hashTable;
    },

    ...Supplier,

    ...SupplierAlias,

    ...SupplierLocation,

    ...SupplierRelation,

    ...SupplierIndustry,

    ...SupplierRelationType,

    ...SupplierModels,

    ...SupplierWeight,
}

export default SuppliersAPI;