// @ts-ignore: Implicit any module
import httpClient from '../configs/axiosConfig';
// @ts-ignore: Implicit any module
import config from '../../config';

const {  RISK_CASE_SERVICE_URL,ALTRUITY_API_URL } = config;

const RiskCase = {
  getAllRiskCase: async function () {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/RiskCase/GetAllRiskCases`);
    return data.result;
  },
  getAllRiskCasesForGridView: async function () {
    const { data } = await httpClient.get(`${ALTRUITY_API_URL}/RiskCase/GetAllRiskCasesForGridView`);
    return data.result;
  },
  getRiskCasesMenu: async function () {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskCase/GetRiskCaseMenuItems`
    );
    return data.result;
  },
  updateRiskCase: async function (riskCase:any) {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/RiskCase/UpdateRiskCase`, riskCase
    );
    return data.result;
  },
  createRiskCase: async function (riskCase:any) {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/RiskCase/CreateRiskCase`, riskCase
    );
    return data.result;
  },
  getRiskCaseById: async function (id: number,) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskCase/GetRiskCaseById/${id}`
    );
    return data.result;
  },
  getSubscribersByRiskCaseId: async function (id: number,) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskCase/GetSubscribersByRiskCaseId/${id}`
    );
    return data.result;
  },
  getRiskCaseBySupplierId: async function (id: number,) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskCase/GetRiskCasesBySuppplierId/${id}`
    );
    return data.result;
  },
  getRiskCaseByKeywordModelId: async function (id: number,) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskCase/GetRiskCasesByKeywordModelId/${id}`
    );
    return data.result;
  },
  getSubscriberDropdown: async function () {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskCase/GetSubscribersDropdown`
    );
    return data.result;
  },
  getOwnersDropdown: async function () {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskCase/GetOwnersDropdown`
    );
    return data.result;
  },
  getSuppliersDropdown: async function () {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskCase/GetSuppliersDropdown`
    );
    return data.result;
  },
  getKeywordModelsDropdown: async function () {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskCase/GetKeywordModelsDropdown`
    );
    return data.result;
  },
  getRiskCaseTemplate: async function (articleId: number[]) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskCase/GetRiskCaseTemplate?${articleId.map(id => `articleIds=${id}`).join('&')}`
    );
    return data.result;
  },
  archiveRiskCase: async function (id: number) {
    const { data } = await httpClient.post(
      `${ALTRUITY_API_URL}/RiskCase/ArchiveRiskCase/${id}`,
    );
    return data.result;
  },
  getRiskCaseFileById: async function (id: number) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskCase/GetRiskCaseFile/${id}`,
    );
    return data.result;
  },
  getUserByEmail: async function (email: string) {
    const { data } = await httpClient.get(
      `${ALTRUITY_API_URL}/RiskCase/GetUserByEmail/${email}`,
    );
    return data.result;
  },

 
};

export default RiskCase;
