import React from "react";
import KeywordModelForm from "../../components/risk-Models/risk-models-form/RiskModelsForm";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import "./index.scss";

interface KeywordModelData {
  id: number;
  modelName: string;
}

interface KeywordModelState {
  allKeywordModel: KeywordModelData[];
  selectedKeywordModel: KeywordModelData | null;
  loading: boolean;
}

interface RootState {
  keywordModel: KeywordModelState;
}

const RiskModels: React.FC = () => {
  const keywordModelData = useSelector(
    (state: RootState) => state.keywordModel.selectedKeywordModel
  );
  const loading = useSelector((state: RootState) => state.keywordModel.loading);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="risk-models-page-container">
      <h4 className="risk-model-title">
        Keyword Model
        {keywordModelData?.modelName.length
          ? ` - ${keywordModelData?.modelName}`
          : ""}
      </h4>

      <KeywordModelForm />
    </div>
  );
};

export default RiskModels;
