export const labelStyles = {
	textTransform: "uppercase",
	position: "relative",
	transform: "scale(0.75)",
	overflow: "visible",
};

export const inputProps_textField = {
	normal: {
		style: {
			backgroundColor: "#F5F5F5",
			borderRadius: "10px",
			height: "46px",
			borderColor: "none",
			fontSize: "14px",
		},
	},
	disabled: {
		style: {
			backgroundColor: "lightgray",
			borderRadius: "10px",
			height: "46px",
			borderColor: "none",
			fontSize: "14px",
			color: "gray",
		},
	},
};

export const textAreaStyles = {
	normal: {
		backgroundColor: "#F5F5F5",
		borderRadius: "10px",
		borderColor: "none",
		fontSize: "14px",
	},
	disabled: {
		backgroundColor: "lightgray",
		borderRadius: "10px",
		borderColor: "none",
		fontSize: "14px",
		color: "gray",
	},
};

export const chipContainerStyles: React.CSSProperties = {
	display: "flex",
	flexWrap: "wrap",
	gap: "8px",
};

export const selectMenuProps = {
	PaperProps: {
		style: {
			width: "50px",
			height: "200px",
		},
	},
};
