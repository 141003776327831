// @ts-ignore: Implicit any module
import httpClient from "../configs/axiosConfig";
// @ts-ignore: Implicit any module
import config from "../../config";
import { ResponseDto, DmsmsAdminSettingsDto } from "../types/types";
const { DMSMS_SERVICE_URL } = config;
const Settings = {
	/**
	 * Gets information for a particular cage code.
	 * @param cage
	 * @returns
	 */
	saveSettings: async function (
		queryData: DmsmsAdminSettingsDto
	): Promise<ResponseDto<DmsmsAdminSettingsDto>> {
		var url = `${DMSMS_SERVICE_URL}/Settings`;
		const { data } = await httpClient.post(url, queryData);
		return data as ResponseDto<DmsmsAdminSettingsDto>;
	},

	/**
	 * Retrieves the DMSMS settings.
	 * @returns
	 */
	getSettings: async function (): Promise<
		ResponseDto<DmsmsAdminSettingsDto>
	> {
		var url = `${DMSMS_SERVICE_URL}/Settings`;
		const { data } = await httpClient.get(url);
		return data as ResponseDto<DmsmsAdminSettingsDto>;
	},
};

export default Settings;
