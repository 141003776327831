// @ts-ignore: Implicit any module
import httpClient from "../configs/axiosConfig";
// @ts-ignore: Implicit any module
import config from "../../config";
import { ResponseDto, UserDto } from "../types/types";
const { PERMISSIONS_SERVICE_URL } = config;
const User = {
	/**
	 * Gets information for a particular cage code.
	 * @param cage
	 * @returns
	 */
	getAllUsers: async function (
		activeOnly: boolean
	): Promise<ResponseDto<UserDto[]>> {
		var url = `${PERMISSIONS_SERVICE_URL}/User?activeOnly=${activeOnly}`;
		const { data } = await httpClient.get(url);
		return data as ResponseDto<UserDto[]>;
	},
};

export default User;
