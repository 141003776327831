import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { SelectBox } from "devextreme-react/select-box";
import { Button } from "@mui/material";
import "./index.scss";

interface RiskMeasureLevel {
  id: number;
  label: string;
  comments: string;
  selectedLevelId: number;
  isRequired: boolean;
}

interface Measure {
  riskMeasureId: number;
  riskMeasureName: string;
  description?: string;
  isRequired?: boolean;
  availableRiskMeasureLevels: RiskMeasureLevel[];
  selectedLevelId?: number;
  comments?: string;
}

interface MeasuresTableProps {
  measures: Measure[];
  onEdit: (measure: Measure) => void;
}

const MeasuresTableRiskCase: React.FC<MeasuresTableProps> = ({
  measures,
  onEdit,
}) => {
  const [rows, setRows] = React.useState(measures);
  const [editRowId, setEditRowId] = React.useState<number | null>(null);
  const [tempSelectedSeverity, setTempSelectedSeverity] = React.useState<{
    [key: number]: string | null;
  }>({});
  const [tempComments, setTempComments] = React.useState<{
    [key: number]: string;
  }>({});
  const [tempSelectedComment, setTempSelectedComment] = React.useState<{
    [key: number]: string;
  }>({});

  const handleSeveritySelection = (newSeverity: string, rowId: number) => {
    setTempSelectedSeverity((prevState) => ({
      ...prevState,
      [rowId]: newSeverity,
    }));
  };

  React.useEffect(() => {
    setRows(measures);
  }, [measures]);

  // const handleSaveClick = (row: Measure) => {
  //   setEditRowId(null);
  // };
  const handleInputKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();
  };
  const handleCommentChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: number
  ) => {
    setTempComments((prev: any) => ({ ...prev, [rowId]: event.target.value }));
  };
  const handleSaveClick = (row: Measure) => {
    const selectedLevel = row.availableRiskMeasureLevels.find(
      (level) => level.label === tempSelectedSeverity[row.riskMeasureId]
    );

    const updatedRow = {
      ...row,
      selectedLevelId: selectedLevel?.id,
      comments: tempComments[row.riskMeasureId] || row.comments,
    };

    onEdit(updatedRow);
    setEditRowId(null);
    setTempComments({});
  };

  const columns: GridColDef[] = [
    {
      field: "riskMeasureName",
      headerName: "Measure",
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        const row = params.row as Measure;

        return (
          <span>
            {row?.isRequired && <span style={{ color: "red" }}>* </span>}
            {row.riskMeasureName}
          </span>
        );
      },
    },
    {
      field: "severity",
      headerName: "Severity",
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        const row = params.row as Measure;
        const isEditing = editRowId === row.riskMeasureId;
        if (isEditing) {
          return (
            <SelectBox
              items={row.availableRiskMeasureLevels}
              displayExpr="label"
              valueExpr="label"
              style={{ marginTop: "2px" }}
              value={
                tempSelectedSeverity[row.riskMeasureId] || row.selectedLevelId
              }
              onValueChanged={(e) =>
                handleSeveritySelection(e.value, row.riskMeasureId)
              }
            />
          );
        }
        const selectedLevel = row.availableRiskMeasureLevels.find(
          (level) => level.id === row.selectedLevelId
        );
        return selectedLevel ? selectedLevel.label : "";
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 250,
      cellClassName: "grid-textfield",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        const row = params.row as Measure;
        const isEditing = editRowId === row.riskMeasureId;

        if (isEditing) {
          return (
            <input
              style={{ height: "100%", width: "100%" }}
              value={tempComments[row.riskMeasureId] || row.comments || ""}
              onChange={(e) => handleCommentChange(e, row.riskMeasureId)}
              onKeyDown={handleInputKeyDown}
            />
          );
        }

        return row.comments || "";
      },
    },
    //   renderCell: (params: GridRenderCellParams) => {
    //     const row = params.row as Measure;

    //     if (
    //       !row ||
    //       !row.availableRiskMeasureLevels ||
    //       row.availableRiskMeasureLevels.length === 0
    //     ) {
    //       return 'No comments available';
    //     }

    //     const sortedLevels = [...row.availableRiskMeasureLevels].sort((a, b) =>
    //       a.label.localeCompare(b.label)
    //     );

    //     return (
    //       tempSelectedComment[row.riskMeasureId] || sortedLevels[0].comments
    //     );
    //   },
    // },

    {
      field: "actions",
      headerName: "Edit",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        const row = params.row as Measure;
        return editRowId === row.riskMeasureId ? (
          <>
            <Button color="primary" onClick={() => handleSaveClick(row)}>
              Save
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setEditRowId(null);
                setTempSelectedSeverity({ [row.riskMeasureId]: null });
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button
            color="primary"
            onClick={() => {
              setTempComments((prevState) => ({
                ...prevState,
                [row.riskMeasureId]: row.comments || "",
              }));
              setEditRowId(row.riskMeasureId);
              setTempSelectedSeverity((prevState) => ({
                ...prevState,
                [row.riskMeasureId]: row.availableRiskMeasureLevels.find(
                  (rml) => rml.id === row.selectedLevelId
                )!.label,
              }));
            }}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  if (!rows || rows.length === 0) {
    return <div></div>;
  }

  return (
    <div
      style={{ height: 400, width: "100%", padding: "20px", marginTop: "20px" }}
    >
      <DataGrid
        rows={rows || []}
        getRowId={(row) => row.riskMeasureId}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5 },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
      />
    </div>
  );
};

export default MeasuresTableRiskCase;
