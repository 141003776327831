import React from "react";
import { Box } from "@mui/material";
import { isNullOrWhitespace } from "../../../utils/validation-helpers";

export default function NsnDetailHeader({ headerData, nsn }) {
	return (
		<Box>
			<Box className="rcorners">
				<div className="nsn-detail-container">
					<div className="nsn-detail-label">NSN:</div>
					<div className="nsn-detail-content">
						{isNullOrWhitespace(headerData?.niinStatus.niin)
							? nsn
							: `${headerData?.niinStatus.fsc ?? ""}${
									headerData?.niinStatus.niin ?? ""
							  }`}
					</div>
				</div>
				<div className="nsn-detail-container">
					<div className="nsn-detail-label">Item Name:</div>
					<div className="nsn-detail-content">
						{headerData?.itemName}
					</div>
				</div>
				<div className="nsn-detail-container">
					<div className="nsn-detail-label">NSN Status:</div>
					<div className="nsn-detail-content">
						{headerData?.niinStatus.statusDesc}
					</div>
				</div>

				{!isNullOrWhitespace(headerData?.niinStatus.replNiin) && (
					<div className="nsn-detail-container">
						<div className="nsn-detail-label">Replacement NSN:</div>
						<div className="nsn-detail-content">
							{headerData?.niinStatus.replFsc ??
								"" + headerData?.niinStatus.replNiin ??
								""}
						</div>
					</div>
				)}

				{!isNullOrWhitespace(headerData?.niinStatus.cancelSource) && (
					<div className="nsn-detail-container">
						<div className="nsn-detail-label">Cancel Source:</div>
						<div className="nsn-detail-content">
							{headerData?.niinStatus.cancelSource}
						</div>
					</div>
				)}
				<div className="nsn-detail-container">
					<div className="nsn-detail-label">
						NSN Status Effective Date::
					</div>
					<div className="nsn-detail-content">
						{headerData?.niinStatus.effDate}
					</div>
				</div>
				<div className="nsn-detail-container">
					<div className="nsn-detail-label">FSC:</div>
					<div className="nsn-detail-content">
						{headerData?.niinStatus.fsc}
					</div>
				</div>
				<div className="nsn-detail-container">
					<div className="nsn-detail-label">FSC Description:</div>
					<div className="nsn-detail-content">
						{headerData?.niinStatus.fscDesc}
					</div>
				</div>
				{!isNullOrWhitespace(headerData?.niinStatus.scheduleb) && (
					<div>
						<div className="nsn-detail-container">
							<div className="nsn-detail-label">Schedule B:</div>
							<div className="nsn-detail-content">
								{headerData?.niinStatus.scheduleb}
							</div>
						</div>
						<div className="nsn-detail-container">
							<div className="nsn-detail-label">
								Schedule B Description:
							</div>
							<div className="nsn-detail-content">
								{headerData?.niinStatus.schedulebDesc}
							</div>
						</div>
					</div>
				)}

				{!isNullOrWhitespace(headerData?.niinStatus.enac) && (
					<div>
						{" "}
						<div>
							<div className="nsn-detail-container">
								<div className="nsn-detail-label">ENAC:</div>
								<div className="nsn-detail-content">
									{headerData?.niinStatus.enac}
								</div>
							</div>
							<div className="nsn-detail-container">
								<div className="nsn-detail-label">
									ENAC Description:
								</div>
								<div className="nsn-detail-content">
									{headerData?.niinStatus.enacDesc}
								</div>
							</div>
						</div>
					</div>
				)}
				<div className="nsn-detail-container">
					<div className="nsn-detail-label">
						Item Name Detail Description:
					</div>
					<div className="nsn-detail-content">{nsn}</div>
				</div>
				<div className="nsn-detail-container">
					<div className="nsn-detail-label">Health Score:</div>
					<div className="nsn-detail-content">
						{(headerData?.niinStatus.healthReasons.length ?? 0) >
							0 && (
							<span>{`${
								headerData?.niinStatus.healthDescription ??
								"Unknown"
							} (${headerData?.niinStatus.healthScore})`}</span>
						)}
					</div>
				</div>
				<div className="nsn-detail-container">
					<div className="nsn-detail-label">Health Info:</div>
					<div className="nsn-detail-content">
						{headerData?.niinStatus.healthReasons.map(
							(healthReason) => (
								<div key={healthReason}>{healthReason}</div>
							)
						)}
					</div>
				</div>
			</Box>
		</Box>
	);
}
