import React from 'react';
import UsersTable from '../../components/User-Admin/Users/UsersTable';
import './Users.scss';

const Users = () => {
  return (
    <div className='users-admin-container'>
      <UsersTable />
    </div>
  );
};

export default Users;
